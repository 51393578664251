import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaRegCircleCheck } from "react-icons/fa6";
import SiteUser from "../../models/data/SiteUser";
import EventEmitter from "../../services/event/event-emitter";
import SearchCustomer from "../search-customer/search-customer";
import StatefullInput from "../statefull-input/statefull-input";
import "./select-customer.scss";
export default function SelectCustomer({ customerEventEmitter }: { customerEventEmitter: EventEmitter<SiteUser[]> }) {
	const [customers, setCustomers] = useState<SiteUser[]>([]);
	const [toAdd, setToAdd] = useState<SiteUser[]>([]);
	const [toRemove, setToRemove] = useState<SiteUser[]>([]);
	const [selectedCustomers, setSelectedCustomers] = useState<SiteUser[]>([]);
	const [searchEventEmitter] = useState<EventEmitter<SiteUser[]>>(new EventEmitter<SiteUser[]>());
	const [localFilter, setLocalFilter] = useState<string>("");
	const [availableSelectAll, setAvailableSelectAll] = useState<boolean>(false);
	const [selectedSelectAll, setSelectedSelectAll] = useState<boolean>(false);

	const filteredCustomers = useMemo(() => {
		return customers.filter((c: SiteUser) => {
			return selectedCustomers.findIndex((sc) => sc.id === c.id) === -1;
		});
	}, [customers, selectedCustomers]);

	const filteredSelectedCustomers = useMemo(() => {
		if (localFilter === "") return selectedCustomers;
		return selectedCustomers.filter((c: SiteUser) => {
			return (
				(c.name !== undefined && c.name.toLowerCase().indexOf(localFilter) > -1) ||
				(c.userId !== undefined && c.userId.toLowerCase().indexOf(localFilter) > -1) ||
				(c.groupId !== undefined && c.groupId.toLowerCase().indexOf(localFilter) > -1)
			);
		});
	}, [localFilter, selectedCustomers]);

	useEffect(() => {
		const customerFetchEvent = searchEventEmitter.subscribe((customers: any[]) => {
			setCustomers([...customers]);
		});
		return () => {
			searchEventEmitter.unsubscribe(customerFetchEvent);
		};
	}, [searchEventEmitter]);

	useEffect(() => {
		customerEventEmitter.emit(selectedCustomers);
	}, [customerEventEmitter, selectedCustomers]);

	useEffect(() => {
		if (availableSelectAll) {
			setToAdd(filteredCustomers);
		} else {
			setToAdd([]);
		}
	}, [availableSelectAll, filteredCustomers]);

	useEffect(() => {
		if (selectedSelectAll) {
			setToRemove(filteredSelectedCustomers);
		} else {
			setToRemove([]);
		}
	}, [selectedSelectAll, filteredSelectedCustomers]);

	const isToAdd = (customer: SiteUser) => {
		return toAdd.findIndex((c) => c.id === customer.id) > -1;
	};

	const isToRemove = (customer: SiteUser) => {
		return toRemove.findIndex((c) => c.id === customer.id) > -1;
	};

	const moveFromToAdd = useCallback(() => {
		setSelectedCustomers([...selectedCustomers, ...toAdd]);
		setToAdd([]);
		setAvailableSelectAll(false);
	}, [selectedCustomers, toAdd]);

	const moveFromToRemove = useCallback(() => {
		const updatedSelected = selectedCustomers.filter((sc) => {
			return toRemove.findIndex((c) => c.id === sc.id) === -1;
		});
		setSelectedCustomers([...updatedSelected]);
		setToRemove([]);
		setSelectedSelectAll(false);
		setLocalFilter("");
	}, [selectedCustomers, toRemove]);

	const ToAddOnSelect = useCallback(
		(customer: SiteUser) => {
			const existingIndex = toAdd.findIndex((c) => c.id === customer.id);
			var updatedCustomers = [...toAdd];
			if (existingIndex > -1) {
				updatedCustomers.splice(existingIndex, 1);
			} else {
				updatedCustomers = [...toAdd, customer];
			}

			setToAdd([...updatedCustomers]);
		},
		[toAdd]
	);

	const ToRemoveOnSelect = useCallback(
		(customer: SiteUser) => {
			const existingIndex = toRemove.findIndex((c) => c.id === customer.id);
			var updatedCustomers = [...toRemove];
			if (existingIndex > -1) {
				updatedCustomers.splice(existingIndex, 1);
			} else {
				updatedCustomers = [...toRemove, customer];
			}

			setToRemove([...updatedCustomers]);
		},
		[toRemove]
	);

	return (
		<div className="mt-4">
			<h3>Select Users</h3>
			<div className="d-block d-md-flex align-items-stretch justify-content-between mb-1 gap-2">
				{/* FILTERED */}
				<div className="w-100 md-100">
					<p className="mb-0">Search application users</p>
					<div className="border rounded bg-light">
						{/*  SEARCH */}
						<div className="border-bottom p-3 pb-2">
							<SearchCustomer searchResults={searchEventEmitter} />
							<div className="mt-2">
								<input
									id={`select_all_available`}
									type={"checkbox"}
									disabled={filteredCustomers.length === 0}
									className="form-check-input"
									checked={availableSelectAll}
									onChange={() => {
										setAvailableSelectAll(!availableSelectAll);
									}}
								/>
								<label htmlFor={`select_all_available`} className="ms-2 form-check-label">
									Select All
								</label>
							</div>
						</div>
						{/* RESULTS */}
						<div className="overflow-auto" style={{ minHeight: "250px", maxHeight: "250px" }}>
							{filteredCustomers.map((customer: SiteUser, index) => {
								return (
									<div key={index} className="customer-option">
										<input
											id={`${customer.id}_customer`}
											type={"checkbox"}
											className="d-none form-check-input"
											checked={isToAdd(customer)}
											onChange={() => ToAddOnSelect(customer)}
										/>
										<label
											htmlFor={`${customer.id}_customer`}
											className="form-check-label interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
										>
											<div style={{ width: "40px", height: "40px" }} className="overflow-hidden">
												<img
													className="d-block w-100 h-100"
													src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${customer.id}`}
													alt={`${customer.name} avatar`}
												/>
											</div>
											<h6 className="mb-0 mx-4 flex-fill-even">{customer.name}</h6>
											<FaRegCircleCheck className="text-success selected-check" />
										</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center">
					{/* TRANSFER */}
					<div className="d-none d-md-flex align-items-center justify-content-center flex-column gap-3 mx-3">
						<button disabled={toAdd.length === 0} className="btn btn-light border p-2 text-secondary" onClick={() => moveFromToAdd()}>
							<FaChevronRight style={{ width: "25px", height: "25px" }} />
						</button>
						<button
							disabled={toRemove.length === 0}
							className="btn btn-light border p-2 text-secondary fw-normal"
							onClick={() => moveFromToRemove()}
						>
							<FaChevronLeft style={{ width: "25px", height: "25px" }} />
						</button>
					</div>

					{/*  */}
					<div className="d-flex d-md-none align-items-center justify-content-center flex-row gap-3 my-3">
						<button disabled={toAdd.length === 0} className="btn btn-light border p-2 text-secondary" onClick={() => moveFromToAdd()}>
							<FaChevronDown style={{ width: "25px", height: "25px" }} />
						</button>
						<button
							disabled={toRemove.length === 0}
							className="btn btn-light border p-2 text-secondary fw-normal"
							onClick={() => moveFromToRemove()}
						>
							<FaChevronUp style={{ width: "25px", height: "25px" }} />
						</button>
					</div>
				</div>

				{/* SELECTED */}
				<div className="w-100 md-100">
					<p className="mb-0">Selected Users</p>
					<div className="border rounded bg-light">
						<div className="border-bottom p-3 pb-2">
							<StatefullInput
								stateId={"filter_selected"}
								defaultValue={localFilter}
								readonly={filteredSelectedCustomers.length === 0}
								placeholder={"Name / User Id / Group Id"}
								inputType={"text"}
								autocompleteType={"off"}
								onChangeCallback={(val: string) => {
									setLocalFilter(val);
								}}
							/>
							<div className="mt-2">
								<input
									id={`select_all_selected`}
									type={"checkbox"}
									className="form-check-input"
									disabled={filteredSelectedCustomers.length === 0}
									checked={selectedSelectAll}
									onChange={() => {
										setSelectedSelectAll(!selectedSelectAll);
									}}
								/>
								<label htmlFor={`select_all_selected`} className="ms-2 form-check-label">
									Select All
								</label>
							</div>
						</div>
						<div className="overflow-auto" style={{ minHeight: "250px", maxHeight: "250px" }}>
							{filteredSelectedCustomers.map((customer: SiteUser, index) => {
								return (
									<div key={index} className="customer-option">
										<input
											id={`selected_${customer.id}_customer`}
											type={"checkbox"}
											className="form-check-input d-none"
											checked={isToRemove(customer)}
											onChange={() => ToRemoveOnSelect(customer)}
										/>
										<label
											htmlFor={`selected_${customer.id}_customer`}
											className="form-check-label interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
										>
											<div style={{ width: "40px", height: "40px" }} className="overflow-hidden">
												<img
													className="d-block w-100 h-100"
													src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${customer.id}`}
													alt={`${customer.name} avatar`}
												/>
											</div>
											<h6 className="mb-0 mx-4 flex-fill-even">{customer.name}</h6>
											<FaRegCircleCheck className="text-success selected-check" />
										</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
