import CustomFormat from "./date-format";

class Division {
	constructor(public amount: number, public name: Intl.RelativeTimeFormatUnit) {}
}

const DIVISIONS = [
	new Division(60, "seconds"),
	new Division(60, "minutes"),
	new Division(24, "hours"),
	new Division(7, "days"),
	new Division(4.34524, "weeks"),
	new Division(12, "months"),
	new Division(Infinity, "years"),
];

export default function RelativeTime(eventDate: Date, forceToday: boolean = false, comparisonDate: Date = new Date()) {
	if (forceToday) {
		if (eventDate.setHours(0, 0, 0, 0) === comparisonDate.setHours(0, 0, 0, 0)) {
			return "Today";
		}
	}

	let duration: number = (new Date(eventDate).getTime() - new Date(comparisonDate).getTime()) / 1000;
	let dist = new Date(eventDate).getTime() - new Date(comparisonDate).getTime();
	if (dist <= -604800000) return CustomFormat(eventDate, "DD MMM YY");

	for (let i = 0; i < DIVISIONS.length; i++) {
		const division = DIVISIONS[i];
		if (Math.abs(Math.round(duration)) < division.amount) {
			var res = new Intl.RelativeTimeFormat("en", {
				localeMatcher: "best fit",
				numeric: "auto",
				style: "long",
			}).format(Math.round(duration), division.name);

			res = res.charAt(0).toUpperCase() + res.slice(1); // Capitalise first character
			return res;
		}
		duration /= division.amount;
	}
}
