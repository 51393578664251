import { HTTP_CONSTANTS } from "../../constants/http-constants";
import UserSession from "../../models/data/user-session";
import LoginRequest from "../../models/requests/LoginRequest";
import RegisterRequest from "../../models/requests/RegisterRequest";
import AccountDetailResponse from "../../models/responses/AccountDetailResponse";
import { IErrorResp } from "../../models/responses/IErrorResp";
import LoginResponse from "../../models/responses/LoginResponse";
import RegisterResponse from "../../models/responses/RegisterResponse";
import EventEmitter from "../event/event-emitter";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";
import UserSessionService from "./user-session-service";

let currentSession: UserSession | null;
const _sessionExpiredEvent = new EventEmitter<void>();
const invalidPermissionsEvent = new EventEmitter<void>();

function _login(loginRequest: LoginRequest, callback: (status: boolean, error?: IErrorResp) => void): void {
	HttpService.post<LoginRequest, LoginResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/login`,
		loginRequest.encode(),
		(_: LoginResponse) => {
			var loggedInSession = new UserSession().FromLoginResponse(_);
			SetCurrentSession(loggedInSession, () => {
				callback(true);
			});
		},
		(_: HttpError) => {
			callback(false, _.error);
		}
	);
}

function _register(registerRequest: RegisterRequest, callback: (status: boolean, error?: IErrorResp) => void): void {
	HttpService.post<RegisterRequest, RegisterResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/register`,
		registerRequest.encode(),
		(_: RegisterResponse) => {
			var loggedInSession = new UserSession().FromRegisterResponse(_);
			SetCurrentSession(loggedInSession, () => {
				callback(true);
			});
		},
		(_: HttpError) => {
			callback(false, _.error);
		}
	);
}

function _deactivate(request: { email: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ email: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account/deactivate`,
		request,
		() => {
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _changePassword(request: { password: string; passwordRepeat: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ password: string; passwordRepeat: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account/change-password`,
		request,
		() => {
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _getDetails(callback: ResponseCallback<AccountDetailResponse>): void {
	HttpService.get<AccountDetailResponse>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/account`,
		(_: AccountDetailResponse) => {
			callback.success(_);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _forgotPassword(forgotRequest: { email: string }, callback: ResponseCallback<any>): void {
	HttpService.post<{ email: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/forgot`,
		forgotRequest,
		() => {
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _recoverPassword(
	recoverRequest: { email: string; password: string; passwordRepeat: string; u: string; c: string },
	callback: ResponseCallback<any>
): void {
	HttpService.post<{ email: string; password: string; passwordRepeat: string; u: string; c: string }, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/authenticate/recover`,
		recoverRequest,
		() => {
			callback.success(null);
		},
		(error: HttpError) => {
			callback.error(error);
		}
	);
}

function _logout(callback: () => void): void {
	localStorage.removeItem("_activeSession");
	currentSession = null;
	callback();
}

function GetSession(): UserSession | undefined {
	if (currentSession == null) currentSession = JSON.parse(localStorage.getItem("_activeSession") as string);
	return currentSession || undefined;
}

function SetCurrentSession(_currentSession: UserSession, callback: () => void): void {
	localStorage.setItem("_activeSession", JSON.stringify(_currentSession));
	currentSession = { ..._currentSession } as UserSession;
	UserSessionService.sessionUpdatedEvent.emit(currentSession).then(() => callback());
}

function isLoggedIn(): boolean {
	const session = GetSession();
	if (session === undefined) return false;
	if (session.expiryDate > new Date()) return false;
	return true;
}

function _getToken(): string {
	if (GetSession() == null) return "";
	if (currentSession == null) return "";
	return currentSession.token;
}

const UserService = {
	sessionExpiredEvent: _sessionExpiredEvent,
	invalidPermissionsEvent,
	getCurrentSession: GetSession,
	login: _login,
	register: _register,
	logout: _logout,
	isLoggedIn,
	getToken: _getToken,
	getDetails: _getDetails,
	changePassword: _changePassword,
	forgotPassword: _forgotPassword,
	recoverPassword: _recoverPassword,
	deactivate: _deactivate,
};

export default UserService;
