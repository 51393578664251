import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/MA_LOGO_TEXT.png";
import heroImage from "../assets/images/HeroImage_2.png";
import StatefullInput from "../components/statefull-input/statefull-input";
import StatefullLabel from "../components/statefull-label/statefull-label";
import LoginRequest from "../models/requests/LoginRequest";
import { IErrorResp } from "../models/responses/IErrorResp";
import EventEmitter from "../services/event/event-emitter";
import UserService from "../services/session/user-service";

export default function LoginPage() {
	const navigate = useNavigate();
	const [email, updateEmail] = useState("");
	const [password, updatePassword] = useState("");
	const [loginRequest, updateLoginRequest] = useState<LoginRequest>();
	const [loading, setLoading] = useState(false);
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			MakeLoginRequest();
		}
	};

	useEffect(() => {
		var updatedLoginReq = new LoginRequest(email, password);
		updateLoginRequest(updatedLoginReq);
	}, [email, password]);

	const MakeLoginRequest = () => {
		if (loginRequest === undefined) return;
		setLoading(true);
		UserService.login(loginRequest, (success: boolean, error?: IErrorResp) => {
			if (success) {
				navigate("/dashboard");
				setLoading(false);
			} else {
				if (error !== undefined) {
					errorState.emit(error);
					setLoading(false);
				}
			}
		});
	};

	return (
		<div className="pg-100 bg-light d-flex align-items-stretch">
			<div className="bg-light col-12 col-lg-6 d-flex align-items-center justify-content-center py-4">
				<div className="w-75 m-auto">
					<div>
						<Link to={"/"}>
							<img src={logo} height="50" className="d-inline-block align-top mb-5" alt="logo" />
						</Link>
						<p className="h3">Log in</p>
						<p className="lead">Welcome to My Announcements, please enter your login credentials below to enter the dashboard.</p>
					</div>

					<form className="mt-4">
						<div className="mb-3">
							<StatefullInput
								stateId="email"
								label="E-mail"
								defaultValue={email}
								inputType="text"
								autocompleteType="email"
								onChangeCallback={(val: string) => updateEmail(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3">
							<StatefullInput
								stateId="password"
								label="Password"
								defaultValue={password}
								inputType="password"
								autocompleteType="current-password"
								onChangeCallback={(val: string) => updatePassword(val)}
								onPressCallback={(e: any) => keyDownHandler(e)}
								stateChangeEvent={errorState}
							/>
						</div>

						<div className="mb-3 text-end">
							{/* <Link to="/auth/recovery">
								<span className="text-primary">
									<strong>Forgot password?</strong>
								</span>
							</Link> */}
						</div>
					</form>

					<StatefullLabel stateId="global" stateChangeEvent={errorState} />

					<div className="border-bottom my-4"></div>

					<div className="d-flex justify-content-between align-items-center">
						<div></div>
						<div className="d-none form-check">
							<input id="rememberMeCheckbox" className="form-check-input" type="checkbox" value="" />
							<label htmlFor="rememberMeCheckbox" className="form-check-label">
								Remember me
							</label>
						</div>

						<div className="">
							<button className="btn btn-primary" disabled={loading} onClick={() => MakeLoginRequest()}>
								{loading ? (
									<>
										<span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
										Loading...
									</>
								) : (
									<>Log in</>
								)}
							</button>
						</div>
					</div>

					<div className="text-center mt-4">
						<span className="me-2">Dont have an account?</span>
						<Link to="/auth/register">
							<span className="text-primary">
								<strong>Sign up</strong>
							</span>
						</Link>
					</div>
				</div>
			</div>

			<div className="bg-primary col-6 lg-6 d-none d-lg-flex align-items-center justify-content-center">
				<div className="text-white text-center">
					<div className="px-5 mb-4">
						<img className="d-block w-100 m-auto" style={{ maxHeight: "450px", maxWidth: "500px" }} alt="" src={heroImage} />
					</div>
					<p className="h1 mb-3">
						<b>Welcome back!</b>
					</p>
					<p className="fs-6 mb-0">Sign in to explore our</p>
					<p className="fs-6">latest features and updates</p>
				</div>
			</div>
		</div>
	);
}
