import React from "react";
import { FaArrowUp, FaArrowsAltH, FaArrowDown } from "react-icons/fa";
import { Area, AreaChart, ResponsiveContainer, Tooltip, TooltipProps, YAxis } from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";
import RelativeTime from "../../misc/helpers/relative-time";
import "./metric-chart-component.scss";
export default function MetricChartComponent({
	metric,
	title,
	description,
	label,
	icon,
	enabled = true,
	maxWidth = 800,
}: {
	metric: { timestamp: number; value: number }[];
	title: string;
	description: string;
	label: string;
	icon: JSX.Element;
	maxWidth?: number;
	enabled?: boolean;
}) {
	const CustomTooltip = ({
		payload,
		label,
		active,
		customLabel,
		icon,
	}: TooltipProps<ValueType, NameType> & { customLabel: string; icon: JSX.Element }) => {
		if (!active) return null;
		if (payload === undefined) return null;
		return (
			<div className="card p-2 bg-dark text-light" style={{ minWidth: "100px" }}>
				<p className="mb-0 text-center fs-6 number">{`${RelativeTime(new Date(payload[0].payload.timestamp), true)}`}</p>
				<p className="mb-0 d-flex justify-content-center align-items-center">
					<span className="me-2">{icon}</span>
					<span>{` ${payload[0].value}`}</span>
				</p>
			</div>
		);
	};

	const renderMetrics = () => {
		if (metric.length === 0) return <></>;
		const formattedMetrics = metric.map((m) => {
			const x = { timestamp: new Date(m.timestamp), value: m.value };
			return x;
		});
		const raw = formattedMetrics.map((m) => m.value);
		const max = raw.sort((a, b) => b - a)[0];
		const total = raw.reduce((p, a) => p + a, 0);
		var yPadding = Math.ceil(max * 0);

		const today = formattedMetrics[formattedMetrics.length - 1];
		const yesterday = formattedMetrics[formattedMetrics.length - 2];
		let todayAnalytic = Math.ceil(((today.value - yesterday.value) / yesterday.value) * 100) || 0;
		if (!isFinite(todayAnalytic)) todayAnalytic = 100;
		if (yPadding === 0) yPadding = 1;

		return (
			<div
				className="card shadow-sm rounded w-content position-relative overflow-hidden flex-fill"
				style={{ minWidth: "250px", maxWidth: `${maxWidth}px`, height: "150px", width: "100%" }}
			>
				<div className="p-2 d-flex align-items-top justify-content-between">
					<div>
						<div className="d-flex align-items-top justify-content-start text-muted">
							<div className="me-2 h5 mb-0 d-flex align-items-center justify-content-center">{icon}</div>
							<h5 className="mb-0">{title}</h5>
						</div>
						<p className="text-muted mb-0 text-start vs">
							<span className="number">{today.value}</span> {label.toLowerCase()} today
						</p>
					</div>

					<div
						className={`d-flex flex-column mb-0 ${
							todayAnalytic > 0 ? "text-success" : todayAnalytic === 0 ? "text-warning" : "text-danger"
						}`}
					>
						<div className="d-flex align-items-center justify-content-between">
							{todayAnalytic > 0 ? <FaArrowUp /> : todayAnalytic === 0 ? <FaArrowsAltH /> : <FaArrowDown />}
							<h5 className="mb-0 ms-1 number">{todayAnalytic}%</h5>
						</div>
						<span className="vs">From yesterday</span>
					</div>
				</div>
				<div className="position-absolute bottom-0 end-0 text-dark" style={{ zIndex: 100, pointerEvents: "none" }}>
					<h1 className="mb-4 me-2 text-end px-2 number">
						<strong>{total}</strong>
					</h1>
				</div>
				{/* width={250}
					height={150} */}
				<ResponsiveContainer>
					<AreaChart className="position-absolute" data={formattedMetrics} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
						<defs>
							<linearGradient id="colourVal" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor="#198754" stopOpacity={0.8} />
								<stop offset="75%" stopColor="#198754" stopOpacity={0} />
							</linearGradient>
						</defs>
						<Area type="monotone" dot={false} dataKey="value" stroke="#198754" fillOpacity={1} fill="url(#colourVal)" />
						<YAxis axisLine={total > 0} hide={true} domain={[0, max + yPadding]} />
						{enabled ? <Tooltip content={<CustomTooltip customLabel={`${label}:`} icon={icon} />} /> : null}
					</AreaChart>
				</ResponsiveContainer>
			</div>
		);
	};

	return renderMetrics();
}
