import { HTTP_CONSTANTS } from "../constants/http-constants";
import Domain from "../models/data/domain";
import DomainSettings from "../models/data/domain-settings";
import UserAccount from "../models/data/user-account";
import InviteRequest from "../models/requests/InviteRequest";
import DomainService from "./domain-service";
import HttpService, { ResponseCallback } from "./http/http-service";

function GetDomainUser(userId: string, callback: ResponseCallback<UserAccount>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<UserAccount>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}/account/${userId}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function InviteUser(invite: InviteRequest, callback: ResponseCallback<any>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.post<InviteRequest, any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}/invite`,
		invite,
		callback.success,
		callback.error,
		callback.always
	);
}

function RemoveUser(userId: string, callback: ResponseCallback<any>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.delete<any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}/remove/${userId}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function CancelSubscription(callback: ResponseCallback<Domain>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.delete<Domain>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/cancel`,
		callback.success,
		callback.error,
		callback.always
	);
}

function RemoveApplication(callback: ResponseCallback<any>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.delete<any>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}/remove`,
		callback.success,
		callback.error,
		callback.always
	);
}

function GetSettings(callback: ResponseCallback<DomainSettings>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<DomainSettings>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function RefreshKey(callback: ResponseCallback<DomainSettings>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<DomainSettings>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/settings/${currentSite.id}/refresh-key`,
		callback.success,
		callback.error,
		callback.always
	);
}
const SettingService = {
	GetSettings,
	RefreshKey,
	GetDomainUser,
	InviteUser,
	RemoveUser,
	CancelSubscription,
	RemoveApplication,
};
export default SettingService;
