import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import CustomFormat from "../../misc/helpers/date-format";
import Invoice from "../../models/data/invoice";
import BillingService from "../../services/billing-service";
import ModalService from "../../services/ui/modal-service";
import "./invoice-list-modal.scss";
export default function InvoiceListModal({ modalId, invoices }: { modalId: string; invoices: Invoice[] }) {
	const InvoiceList = () => {
		return (
			<>
				{invoices.map((i: Invoice, index: number) => {
					return (
						<div
							key={index}
							className="interactable px-3 py-3 list-group-item"
							onClick={() => {
								window.open(i.invoice_pdf, "_blank");
							}}
						>
							<div className="d-flex align-items-center justify-content-between">
								<h5 className="fs-4 mb-0 me-2">{BillingService.RenderPrice(i.amount)}</h5>
								<h5 className="fs-4 mb-0">{i.customer_name}</h5>

								<div className="flex-fill"></div>

								<small className="me-3 mb-0">{CustomFormat(i.date)}</small>
								<FaChevronRight className="h5 mb-0" />
							</div>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<div className="card">
			<div className="d-flex justify-content-between align-items-center p-3 border-bottom">
				<h3 className="mb-1">Past invoices</h3>
			</div>

			{invoices.length === 0 ? (
				<>
					<p className="w-100 text-center my-3">No Invoices Available.</p>
				</>
			) : (
				InvoiceList()
			)}

			<div className="border-top p-3 d-flex align-items-center justify-content-end">
				<button className="btn btn-outline-primary me-0" onClick={() => ModalService.CloseModal(modalId)}>
					Close
				</button>
			</div>
		</div>
	);
}
