import React, { useCallback, useEffect, useState } from "react";
import { FaCheck, FaChevronLeft, FaUserMinus, FaX } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import AppAnnouncements from "../../components/app-announcements/app-announcements";
import ConfirmationModal, { ConfirmationOptions } from "../../components/confirmation-modal/confirmation-modal";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import RelativeTime from "../../misc/helpers/relative-time";
import SiteUser from "../../models/data/SiteUser";
import { ToastConfig } from "../../models/interfaces/IToast";
import CustomerService from "../../services/customer-service";
import { HttpError } from "../../services/http/http-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import "./customer-detail-page.scss";
export default function CustomerDetailPage() {
	const navigate = useNavigate();
	const { customerId } = useParams();
	const [customer, setCustomer] = useState<SiteUser>();

	useEffect(() => {
		if (customerId === undefined) return;
		CustomerService.GetCustomer(customerId, {
			success(_) {
				setCustomer(_);
			},
			error(err) {},
			always() {},
		});
	}, [customerId]);

	const RemoveCustomer = useCallback(() => {
		if (customer === undefined) return;
		ModalService.OpenModal(
			"delete_customer_conf",
			<ConfirmationModal
				modalId={"delete_customer_conf"}
				options={
					new ConfirmationOptions(
						"Are you sure?",
						"Deleting this user will remove all related history and can not be reversed.",
						"Okay",
						"Cancel"
					)
				}
				callback={(_: boolean) => {
					if (_) {
						LoadingOverlay.Show();
						CustomerService.DeleteCustomer(customer.id, {
							success: (_: SiteUser) => {
								navigate(-1);
								ToastService.OpenToast(
									"customer_deleted",
									"Customer Removed",
									"Delete Successful.",
									FaCheck,
									new ToastConfig().success().hide(3000)
								);
							},
							error: (err: HttpError) => {
								ToastService.OpenToast(
									"customer_delete_fail",
									"Customer Not Removed, please try again.",
									"Delete Failed",
									FaX,
									new ToastConfig().danger().hide(3000)
								);
							},
							always() {
								LoadingOverlay.Hide();
							},
						});
					}
				}}
			/>,
			"sm"
		);
	}, [customer, navigate]);

	const CustomerDetail = () => {
		if (customer === undefined) return <></>;
		return (
			<div>
				<div className="d-flex align-items-center justify-content-start">
					<div
						onClick={() => navigate(-1)}
						className="interactable rounded-circle d-flex align-items-center justify-content-center"
						style={{ width: "30px", height: "30px" }}
					>
						<FaChevronLeft className="h5 mb-0" />
					</div>

					<h4 className="mb-0 mx-1">Customer Details</h4>
					<div className="flex-fill"></div>
					<div>
						<button
							onClick={() => {
								RemoveCustomer();
							}}
							className={`btn btn-outline-danger d-flex align-items-center justify-content-center`}
						>
							<span>Remove</span>
							<FaUserMinus className="h5 ms-2 mb-0" />
						</button>
					</div>
				</div>
				<div className="card overflow-hidden mt-2 p-3">
					<div>
						<div className="d-flex align-items-start justify-content-between mb-4">
							<div className="d-flex align-items-start justify-content-between">
								<div style={{ width: "75px", height: "75px" }} className="overflow-hidden">
									<img
										className="d-block w-100 h-100"
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${customer.id}`}
										alt={`${customer.name} avatar`}
									/>
								</div>
								<div className="ms-3">
									<p className="mb-0">
										Id: <strong>{customer.id}</strong>
									</p>
									<p className="mb-0">
										Name: <strong>{customer.name}</strong>
									</p>
									<p className="mb-0">
										Group: <strong>{customer.groupId || "N/A"}</strong>
									</p>
								</div>
							</div>
							<p className="mb-0 number">
								Last Seen: <strong>{RelativeTime(customer.lastSeen)}</strong>
							</p>
						</div>
						<div>
							<p className="mb-0">Meta Data</p>
							<pre className="mb-0 bg-light p-2 rounded">{JSON.stringify(customer.metaData, null, 2)}</pre>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const CustomerAnnouncements = () => {
		if (customer === undefined) return <></>;
		return (
			<div className="mt-4">
				<div className="d-flex align-items-center justify-content-between mx-1 gap-3">
					<h4 className="mb-0">User Announcements</h4>
					<div className="d-none d-md-block">
						<small className="text-muted">(These are the announcements available for the user)</small>
					</div>
				</div>
				<div className="card overflow-hidden mt-2">
					<AppAnnouncements userId={customer.id} />
				</div>
			</div>
		);
	};

	return (
		<>
			{CustomerDetail()}
			{CustomerAnnouncements()}
		</>
	);
}
