import React, { useEffect, useState } from "react";
import { FaInfo, FaTriangleExclamation, FaCircleExclamation } from "react-icons/fa6";
import RelativeTime from "../../misc/helpers/relative-time";
import UserAnnouncement from "../../models/data/user-announcement";
import AnnouncementService from "../../services/announcement-service";
import ImplementationService from "../../services/implementation-service";
import ToastService from "../../services/ui/toast-service";
import "./app-announcements.scss";
export default function AppAnnouncements({ userId = undefined }: { userId?: string | undefined }) {
	const [announcements, setAnnouncements] = useState<UserAnnouncement[]>([]);

	useEffect(() => {
		if (userId === undefined) {
			ImplementationService.GetAnnouncements({
				success(_: UserAnnouncement[]) {
					if (_ === null || _ === undefined || _.length === 0) return;
					setAnnouncements(_);
				},
				error(err) {
					ToastService.ShowRespError("user_announcement_fail", err);
				},
			});
		} else {
			AnnouncementService.GetUserAnnouncements(userId, {
				success(_) {
					setAnnouncements(_);
				},
				error(err) {
					ToastService.ShowRespError("user_announcement_fail", err);
				},
			});
		}
	}, [userId]);

	return (
		<div>
			{announcements !== undefined &&
				announcements.map((announcement: UserAnnouncement) => {
					return (
						<div
							key={announcement.id}
							className="interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
						>
							<div className={`p-1 rounded-circle text-light`}>
								{announcement.status === "INFO" ? (
									<FaInfo className="h3 mb-0 text-success" />
								) : announcement.status === "WARNING" ? (
									<FaTriangleExclamation className="h3 mb-0 text-warning" />
								) : (
									<FaCircleExclamation className="h3 mb-0 text-danger" />
								)}
							</div>
							<span className="mb-0 flex-fill ms-3">{announcement.message}</span>
							<span className="mx-4 number">{RelativeTime(announcement.date)}</span>
							<div className="d-flex align-itmes-center justify-content-between gap-2">
								<div className="d-flex align-items-center justify-content-center">
									<div
										className={`${announcement.read ? "bg-secondary" : "bg-primary"} rounded-circle`}
										style={{ width: "10px", height: "10px" }}
									></div>
								</div>
								<span>{announcement.read ? "Seen" : "Unread"}</span>
							</div>
						</div>
					);
				})}
		</div>
	);
}
