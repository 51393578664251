import { HTTP_CONSTANTS } from "../constants/http-constants";
import SiteUser from "../models/data/SiteUser";
import { IPaginationHeaders } from "../models/interfaces/IPaginationHeaders";
import PaginationDataResponse from "../models/responses/PaginationDataResponse";
import DomainService from "./domain-service";
import HttpService, { ResponseCallback } from "./http/http-service";

function GetCustomers(filter: string, pagination: IPaginationHeaders, callback: ResponseCallback<PaginationDataResponse<SiteUser[]>>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<PaginationDataResponse<SiteUser[]>>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/customers/${currentSite.id}/${filter}`,
		callback.success,
		callback.error,
		callback.always,
		{
			pagination: JSON.stringify(pagination),
		}
	);
}

function GetCustomer(customerId: string, callback: ResponseCallback<SiteUser>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<SiteUser>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/customers/${currentSite.id}/customer/${customerId}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function DeleteCustomer(customerId: string, callback: ResponseCallback<SiteUser>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.delete<SiteUser>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/customers/${currentSite.id}/customer/${customerId}`,
		callback.success,
		callback.error,
		callback.always
	);
}

const CustomerService = {
	GetCustomers,
	GetCustomer,
	DeleteCustomer,
};

export default CustomerService;
