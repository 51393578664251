import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/MA_LOGO_TEXT.png";
import "./nav-bar.scss";
export default function NavBar() {
	const [background, setBackground] = useState<string>();

	useEffect(() => {
		window.onscroll = () => {
			if (window.scrollY !== 0) setBackground("bg-white shadow-sm");
			else setBackground("");
		};
	});

	return (
		<div className={`py-3 position-fixed start-0 end-0 top-0 ${background}`} style={{ zIndex: 1000 }}>
			<div className="page-width px-3 d-flex align-items-center justify-content-between">
				{/* LOGO */}
				<img src={logo} height="45" className="d-block" alt="logo" />
				<div className="flex-fill"></div>
				{/* NAV */}
				<div className="d-none d-md-flex align-items-center justify-content-center gap-4 flex-fill">
					<Nav.Link href="/#how-it-works">
						<span className="fs-6">How It Works</span>
					</Nav.Link>
					<Nav.Link href="/#features">
						<span className="fs-6">Features</span>
					</Nav.Link>
					<Nav.Link href="/#pricing">
						<span className="fs-6">Pricing</span>
					</Nav.Link>
				</div>
				<div className="flex-fill"></div>
				{/* ACTIONS */}
				<div className="d-flex align-items-center justify-content-between">
					<Link to={"/auth/login"}>
						<button className="btn">Sign In</button>
					</Link>
					<Link to={"/auth/register"}>
						<button className="btn btn-primary">Sign Up</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
