import React from "react";
import { FaTwitter } from "react-icons/fa";
import "./contact-form-component.scss";
export default function ContactFormComponent() {
	return (
		<div className="p-2">
			<div className="d-block w-100">
				<a
					className="h5 text-center d-block mb-0 interact text-primary"
					href="https://twitter.com/myannouncements"
					target={"_blank"}
					rel="noreferrer"
				>
					<FaTwitter /> Message us on Twitter.
				</a>
			</div>
		</div>
	);
}
