export enum SORTBY {
	ASC = "ASC", // OLDEST FIRST
	DESC = "DESC", // NEWEST FIRST
}

export interface DateRange {
	startDate: Date;
	endDate: Date;
}

export interface IPaginationHeaders {
	page: number;
	pageSize: number;
	sortBy: SORTBY;
	dateRange?: DateRange;
}

export interface IPaginationResponse {
	page: number;
	pageSize: number;
	totalCount: number;
	hasPrev: boolean;
	hasNext: boolean;
	nextPage: number;
	prevPage: number;
}
