import { HTTP_CONSTANTS } from "../constants/http-constants";
import { AnnouncementMetrics } from "../models/data/announcement-metrics";
import DomainService from "./domain-service";
import HttpService, { ResponseCallback } from "./http/http-service";

function GetAnnouncementMetrics(announcementId: string, callback: ResponseCallback<any>) {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) return;
	HttpService.get<AnnouncementMetrics>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/reporting/${currentSite.id}/${announcementId}/metrics/14`,
		callback.success,
		callback.error,
		callback.always
	);
}

const MetricService = {
	GetAnnouncementMetrics,
};

export default MetricService;
