import React, { useEffect } from "react";
import { FaLock } from "react-icons/fa6";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal, { ConfirmationOptions } from "../../components/confirmation-modal/confirmation-modal";
import { ToastConfig } from "../../models/interfaces/IToast";
import UserService from "../../services/session/user-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import ImplementationService from "../../services/implementation-service";

export default function Theme() {
	const navigate = useNavigate();

	useEffect(() => {
		if (window.location.href.indexOf("dashboard") > -1) document.body.classList.add("page-lock");
		else document.body.classList.remove("page-lock");

		if (UserService.isLoggedIn()) {
			const session = UserService.getCurrentSession();
			if (session === undefined) return; // wont happen
			ImplementationService.IdentifyCustomer();
		}
	}, [navigate]);

	useEffect(() => {
		var sesionExpiredSub = UserService.sessionExpiredEvent.subscribe(() => {
			if (window.location.pathname.indexOf("/dashboard") === -1) return;
			ToastService.OpenToast(
				"session_expired",
				"Session Expired",
				"Your session has expired, please log back in.",
				FaLock,
				new ToastConfig({
					borderColour: "warning",
					headerColour: "warning",
					autoHide: false,
				})
			);
			navigate("/auth/login");
		});

		var invalidPermissionSub = UserService.invalidPermissionsEvent.subscribe(() => {
			ModalService.OpenModal(
				"no_permission",
				<ConfirmationModal
					modalId={"no_permission"}
					options={
						new ConfirmationOptions(
							"Access Denied",
							"You don't have permission to view this resource, please contact your application admin. If you believe this to be an error please contact support.",
							"",
							"Okay"
						)
					}
					callback={(_: boolean) => void {}}
				/>
			);
		});

		return () => {
			UserService.sessionExpiredEvent.unsubscribe(sesionExpiredSub);
			UserService.invalidPermissionsEvent.unsubscribe(invalidPermissionSub);
		};
	}, [navigate]);

	try {
		const location = useLocation();

		const routeThemes: { path: string; colour: string }[] = [
			{ path: "/", colour: "#F8FAFB" },
			{ path: "/dashboard", colour: "#FFF" },
			{ path: "/dashboard/subscription", colour: "#FFF" },
			{ path: "/auth/login", colour: "#F8FAFB" },
			{ path: "/auth/register", colour: "#F8FAFB" },
			{ path: "/auth/recovery", colour: "#F8FAFB" },
		];

		var metaTag = document.querySelector('meta[name="theme-color"]');
		let themeColour = "#FFF";

		routeThemes.forEach((_: { path: string; colour: string }) => {
			if (_.path === location.pathname) themeColour = _.colour;
		});

		if (metaTag != null) metaTag.setAttribute("content", themeColour);
	} catch (exception: any) {
		console.warn(exception);
	}

	const idevice =
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
		(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

	return (
		<div className={`${idevice ? "i_device" : ""}`}>
			<Outlet />
		</div>
	);
}
