import React, { useCallback, useState } from "react";
import ApplicationCreateRequest from "../../models/requests/ApplicationCreateRequest";
import { IErrorResp } from "../../models/responses/IErrorResp";
import DomainService from "../../services/domain-service";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import StatefullInput from "../statefull-input/statefull-input";
import "./create-application-modal.scss";

enum CreateModalPageStep {
	Create,
	Complete,
}

export default function CreateApplicationModal({ modalId }: { modalId: string }) {
	const [applicationCreateRequest, setApplicationCreateRequest] = useState<ApplicationCreateRequest>(new ApplicationCreateRequest());
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const [pageStep, setPageStep] = useState<CreateModalPageStep>(CreateModalPageStep.Create);

	const closeModalHandler = useCallback(() => {
		DomainService.RefetchDomains();
		ModalService.CloseModal(modalId);
	}, [modalId]);

	const CreateApplication = useCallback(() => {
		errorState.emit({});
		if (applicationCreateRequest === undefined) return;
		DomainService.CreateApplication(applicationCreateRequest, {
			success(_) {
				setPageStep(CreateModalPageStep.Complete);
				ToastService.ShowSuccess("create_success", "Application created successfully!");
			},
			error(err: HttpError) {
				errorState.emit(err.error);
				ToastService.ShowRespError("create_fail", err);
			},
		});
	}, [applicationCreateRequest, errorState]);

	const getDomainStepUI = () => {
		return (
			<>
				<div>
					<h3>Create An Application</h3>
				</div>
				<div className="border-top border-bottom py-3">
					<p className="mb-3 border-bottom border-light pb-3">
						To get started, let us know the name of the application. Multiple accounts can manage the same application once configured.
					</p>
					<StatefullInput
						stateId={`application_name`}
						label={"Application Name"}
						placeholder={"eg: MyAnnouncements"}
						defaultValue={""}
						inputType={"text"}
						autocompleteType={"off"}
						onChangeCallback={(val: string) => {
							const _ = { ...applicationCreateRequest, applicationName: val } as ApplicationCreateRequest;
							setApplicationCreateRequest(_);
						}}
						onPressCallback={(_: any) => {}}
						stateChangeEvent={errorState}
					/>
				</div>
				<div className="mt-2 d-flex align-items-center gap-2 justify-content-end">
					<button className="d-block btn btn-outline-danger" onClick={() => closeModalHandler()}>
						Cancel
					</button>
					<button className="d-block btn btn-success" onClick={() => CreateApplication()}>
						Create
					</button>
				</div>
			</>
		);
	};

	const CompleteUI = () => {
		return (
			<>
				<div>
					<h3>Complete</h3>
				</div>
				<div className="border-top border-bottom py-3">
					<p className="">Congratulations! Your application is configured and ready to use.</p>
				</div>
				<div className="mt-2 d-flex align-items-center gap-2 justify-content-end">
					<button className="d-block btn btn-success" onClick={() => closeModalHandler()}>
						Complete
					</button>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="p-3">
				{pageStep === CreateModalPageStep.Create ? getDomainStepUI() : null}
				{pageStep === CreateModalPageStep.Complete ? CompleteUI() : null}
			</div>
		</>
	);
}
