import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import JSConfetti from "js-confetti";
import Theme from "./misc/theme/theme";
import LoginPage from "./pages/login-page";
import RegisterPage from "./pages/register-page";
import DashboardPage from "./pages/dashboard-page";
import DashboardLanding from "./pages/dashboard-landing/dashboard-landing";
import DashboardAccount from "./pages/dashboard-account/dashboard-account";
import DashboardSettings from "./pages/dashboard-settings/dashboard-settings";
import DashboardActivity from "./pages/dashboard-activity/dashboard-activity";
import DashboardCustomers from "./pages/dashboard-customers/dashboard-customers";
import ModalService from "./services/ui/modal-service";
import ToastService from "./services/ui/toast-service";
import LoadingOverlay from "./components/loading-overlay/loading-overlay";
import CreateAnnouncementPage from "./pages/create-announcement-page/create-announcement-page";
import AnnouncementDetailPage from "./pages/announcement-detail-page/announcement-detail-page";
import CustomerDetailPage from "./pages/customer-detail-page/customer-detail-page";
import DocumentationPage from "./pages/documentation-page/documentation-page";
import AccountDetailPage from "./pages/account-detail-page/account-detail-page";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LandingPage from "./pages/landing-page/landing-page";
import { Tier, TierFeature } from "./components/pricing-card/pricing-card";
import { SubscriptionTier } from "./models/data/subscription-tier";
import { FaBuilding, FaBuildingColumns, FaCampground, FaIgloo } from "react-icons/fa6";

declare global {
	interface Window {
		confetti: any;
	}
}

export const APP_TIERS: { ESSENTIAL: Tier; PREMIUM: Tier; ADVANCED: Tier; ENTERPRISE: Tier } = {
	ESSENTIAL: {
		backgroundColours: ["#EE872D", "#ffb87a"],
		tierLevel: SubscriptionTier.ESSENTIAL,
		iconColour: "text-success",
		icon: <FaCampground />,
		title: "Essential",
		sub: "Great for Getting Started",
		pricePrefix: "£",
		price: 50,
		priceSuffix: "pm",
		features: [
			new TierFeature("50 Customers", true),
			new TierFeature("5 Announcements/mo", true),
			new TierFeature("API Integration", true),
			new TierFeature("User targeting", false),
			new TierFeature("Scheduled Posts", false),
			new TierFeature("Performance Metrics", false),
			new TierFeature("Customer Groups", false),
			new TierFeature("AI Access", false),
		],
		accent: false,
		buttonClass: "btn-success",
	},
	PREMIUM: {
		backgroundColours: ["#52fc03", "#37ad00"],
		tierLevel: SubscriptionTier.PREMIUM,
		iconColour: "text-danger",
		icon: <FaIgloo />,
		title: "Premium",
		sub: "Perfect for startups",
		pricePrefix: "£",
		price: 125,
		priceSuffix: "pm",
		features: [
			new TierFeature("200 Customers", true),
			new TierFeature("20 Announcements/mo", true),
			new TierFeature("API Integration", true),
			new TierFeature("User targeting", true),
			new TierFeature("Scheduled Posts", true),
			new TierFeature("Performance Metrics", true),
			new TierFeature("Customer Groups", false),
			new TierFeature("AI Access", false),
		],
		accent: false,
		buttonClass: "btn-danger",
	},
	ADVANCED: {
		backgroundColours: ["#52fc03", "#37ad00"],
		tierLevel: SubscriptionTier.ADVANCED,
		iconColour: "text-primary",
		icon: <FaBuilding />,
		title: "Advanced",
		sub: "Our best deal",
		pricePrefix: "£",
		price: 250,
		priceSuffix: "pm",
		features: [
			new TierFeature("1,000 Customers", true),
			new TierFeature("500 Announcements/mo", true),
			new TierFeature("API Integration", true),
			new TierFeature("User targeting", true),
			new TierFeature("Scheduled Posts", true),
			new TierFeature("Performance Metrics", true),
			new TierFeature("Customer Groups", true),
			new TierFeature("AI Access", false),
		],
		accent: true,
		buttonClass: "btn-primary",
	},
	ENTERPRISE: {
		backgroundColours: ["#52fc03", "#37ad00"],
		tierLevel: SubscriptionTier.ENTERPRISE,
		iconColour: "text-warning",
		icon: <FaBuildingColumns />,
		title: "Enterprise",
		sub: "Large scale projects",
		pricePrefix: "£",
		price: 400,
		priceSuffix: "pm",
		features: [
			new TierFeature("UNLIMITED Customers", true),
			new TierFeature("UNLIMITED Announcements/mo", true),
			new TierFeature("API Integration", true),
			new TierFeature("User targeting", true),
			new TierFeature("Scheduled Posts", true),
			new TierFeature("Performance Metrics", true),
			new TierFeature("Customer Groups", true),
			new TierFeature("AI Access", true),
		],
		accent: false,
		buttonClass: "btn-warning",
	},
};

function App() {
	window.confetti = new JSConfetti();

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Router>
					<Routes>
						<Route path="/" element={<Theme />}>
							{/* LANDING */}
							<Route index element={<LandingPage />}></Route>

							{/* AUTH */}
							<Route path="auth">
								<Route path="login" element={<LoginPage />} />
								<Route path="register" element={<RegisterPage />} />

								{/* REFERER SIGNUPS */}
								<Route path="referer/:refererName">
									<Route path="register" element={<RegisterPage useReferer={true} />} />
								</Route>
							</Route>

							{/* DASHBOARD ROUTES */}
							<Route path="dashboard" element={<DashboardPage />}>
								<Route index element={<DashboardLanding />} />

								<Route path="customers" element={<DashboardCustomers />} />
								<Route path="customer-detail/:customerId" element={<CustomerDetailPage />} />

								<Route path="activity" element={<DashboardActivity />} />

								<Route path="create-announcement" element={<CreateAnnouncementPage />} />
								<Route path="announcement-detail/:announcementId" element={<AnnouncementDetailPage />} />

								<Route path="settings" element={<DashboardSettings />} />
								<Route path="account-detail/:accountId" element={<AccountDetailPage />} />

								<Route path="account-settings" element={<DashboardAccount />} />

								<Route path="documentation" element={<DocumentationPage />} />

								<Route path="order-confirmation/:siteId" element={<DashboardLanding />} />
								<Route path="order-cancel/:siteId" element={<DashboardLanding />} />

								<Route path="*" element={<DashboardLanding />} />
							</Route>
						</Route>
					</Routes>

					<div>
						<ModalService.Component />
						<ToastService.Component />
						<LoadingOverlay.Component />
					</div>
				</Router>
			</LocalizationProvider>
		</>
	);
}

export default App;
