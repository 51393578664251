import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import CustomerList from "../../components/customer-list/customer-list";
import SearchCustomer from "../../components/search-customer/search-customer";
import SiteUser from "../../models/data/SiteUser";
import { IPaginationResponse } from "../../models/interfaces/IPaginationHeaders";
import EventEmitter from "../../services/event/event-emitter";
import "./dashboard-customers.scss";
export default function DashboardCustomers() {
	const [customerChangeEvent] = useState<EventEmitter<SiteUser[]>>(new EventEmitter<SiteUser[]>());
	const [loadingChangeEvent] = useState<EventEmitter<boolean>>(new EventEmitter<boolean>());
	const [paginationData, setPaginationData] = useState<IPaginationResponse>();
	const [paginateEmit] = useState<EventEmitter<number>>(new EventEmitter<number>());
	const [customers, setCustomers] = useState<SiteUser[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const c_sub = customerChangeEvent.subscribe((_: SiteUser[]) => {
			setCustomers(_);
		});

		loadingChangeEvent.subscribe((_: boolean) => {
			setLoading(_);
		});

		return () => {
			customerChangeEvent.unsubscribe(c_sub);
		};
	}, [customerChangeEvent, loadingChangeEvent]);

	const DrawPagination = () => {
		return paginationData === undefined || paginationData.page === -1 ? (
			<div></div>
		) : (
			<div className="mt-2 px-2 py-1 bg-white rounded d-flex justify-content-between align-items-center">
				<span className="text-muted small">
					{paginationData.page * paginationData.pageSize + 1} - {paginationData.page * paginationData.pageSize + customers.length} of{" "}
					{paginationData.totalCount} records
				</span>
				<div></div>
				<div>
					<button
						className={`btn btn-sm ${paginationData.hasPrev ? "btn-primary" : "btn-outline-light"}`}
						disabled={!paginationData.hasPrev}
						onClick={() => {
							paginateEmit.emit(paginationData.page - 1);
						}}
					>
						<FaChevronLeft />
					</button>
					<div className="d-inline-block px-1"></div>
					<button
						className={`btn btn-sm ${paginationData.hasNext ? "btn-primary" : "btn-outline-light"}`}
						disabled={!paginationData.hasNext}
						onClick={() => {
							paginateEmit.emit(paginationData.page + 1);
						}}
					>
						<FaChevronRight />
					</button>
				</div>
			</div>
		);
	};

	return (
		<div>
			<h4 className="mx-1 mb-2">Search Customers</h4>
			<SearchCustomer
				loadingEmitter={loadingChangeEvent}
				searchResults={customerChangeEvent}
				paginationResponse={(_: IPaginationResponse) => {
					setPaginationData(_);
				}}
				paginateEmitter={paginateEmit}
			/>
			{loading ? (
				<>
					<div className="text-center">
						<div className="mt-2 spinner-border text-primary" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</>
			) : (
				<>
					{DrawPagination()}
					<CustomerList customers={customers} />
					{DrawPagination()}
				</>
			)}
		</div>
	);
}
