import EventEmitter from "../../services/event/event-emitter";

export default class IModal {
	public onClose: EventEmitter<void> = new EventEmitter<void>();
	constructor(
		public id: string,
		public index: number,
		public content: JSX.Element,
		public active: boolean = false,
		public close: () => void,
		public size: "sm" | "lg" | "xl" | undefined = undefined
	) {}
}
