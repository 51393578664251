import { Referer } from "../../models/data/referer";

// REFERER KV
const referers: Referer[] = [
	{ keyName: "appsumo", viewName: "AppSumo 🌮", icon: "" },
	{ keyName: "mdp", viewName: "MyDataPro 🗃️", icon: "" },
];

// SERVICE METHODS

function _associationComponent(refererName: string): JSX.Element {
	const referer = referers.find((r) => r.keyName === refererName);
	if (referer === null || referer === undefined) return <></>;

	return (
		<>
			<p className="mt-2 mb-0">In association with {referer.viewName}</p>
		</>
	);
}

// EXPORTS

const RefererService = {
	InAssociationComponent: _associationComponent,
};

export default RefererService;
