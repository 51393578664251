import React from "react";
import Invoice from "../../models/data/invoice";
import LineItem from "../../models/data/line-item";
import BillingService from "../../services/billing-service";
import ModalService from "../../services/ui/modal-service";
import "./invoice-modal.scss";

export default function InvoiceModal({
	modalId,
	invoice,
	includeConfirmation,
	callback,
}: {
	modalId: string;
	invoice: Invoice;
	includeConfirmation: boolean;
	callback: (confirm: boolean) => void;
}) {
	const renderLineItems = () => {
		return invoice.lines.map((li: LineItem, i: number) => {
			return (
				<div key={i} className="d-flex justify-content-between align-items-center gap-2">
					<span>{li.description}</span>
					<span className="h5 number">{BillingService.RenderPrice(li.amount)}</span>
				</div>
			);
		});
	};

	const renderCreditUsage = () => {
		if (invoice.customer_end_balance === 0) return null;
		return (
			<div className="d-flex justify-content-between align-items-center gap-2">
				<span>Remaining Account Credit</span>
				<span className="h5 number">{BillingService.RenderPrice(-invoice.customer_end_balance)}</span>
			</div>
		);
	};

	return (
		<div className="bg-white p-3 rounded mb-2">
			<div>
				<div className="d-flex justify-content-between align-items-center">
					<h3 className="mb-1">View Invoice</h3>
					{!includeConfirmation && (
						<button className="btn btn-outline-primary" onClick={() => ModalService.CloseModal(modalId)}>
							Close
						</button>
					)}
				</div>
				{!includeConfirmation && <p className="text-muted mb-2">This invoice is not final and can change.</p>}
				<div className="border-bottom mb-3"></div>
				<div className="px-2">{renderLineItems()}</div>
				<div className="px-2">{renderCreditUsage()}</div>
				<div className="mt-4">
					{invoice.discount === undefined ? (
						<></>
					) : (
						<p className="h6 text-end mb-4">
							Discount: <span className="ms-1 number">{invoice.discount.discountCode}</span>
						</p>
					)}
					<p className="h6 text-end mb-2">
						Sub Total: <span className="ms-1 number">{BillingService.RenderPrice(invoice.subtotal)}</span>
					</p>
					<p className="h4 text-end mb-0">
						Total <span className="ms-1 number">{BillingService.RenderPrice(invoice.amount)}</span>
					</p>
				</div>
				<div className="border-bottom my-3"></div>
				<p className="text-muted mb-1">Amounts are pro-rata for any remaining time on an updated subscription.</p>
				<div className="">
					<p className="text-muted mb-1">
						Bill for: <strong className="ms-1">{invoice.customer_name}</strong>
					</p>
				</div>

				{includeConfirmation && (
					<div className="d-flex justify-content-end align-items-center mt-4">
						<button
							className="btn btn-outline-warning me-2"
							onClick={() => {
								ModalService.CloseModal(modalId);
								callback(false);
							}}
						>
							Decline
						</button>
						<button className="btn btn-primary" onClick={() => callback(true)}>
							Confirm
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
