import React, { useState } from "react";
import { FaLock } from "react-icons/fa";
import { ToastConfig } from "../../models/interfaces/IToast";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import UserService from "../../services/session/user-service";
import ToastService from "../../services/ui/toast-service";
import StatefullInput from "../statefull-input/statefull-input";
import StatefullLabel from "../statefull-label/statefull-label";
import { Buffer } from "buffer";
import "./reset-password.scss";

export default function ResetPassword() {
	const [currentPassword, updateCurrentPassword] = useState("");
	const [password, updatePassword] = useState("");
	const [passwordRepeat, updatePasswordRepeat] = useState("");
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const makeChangeRequest = () => {
		const req = {
			currentPassword: Buffer.from(currentPassword).toString("base64"),
			password: Buffer.from(password).toString("base64"),
			passwordRepeat: Buffer.from(passwordRepeat).toString("base64"),
		};
		UserService.changePassword(req, {
			success(_) {
				updatePassword("");
				updatePasswordRepeat("");
				ToastService.OpenToast(
					"password_changed",
					"Password Changed",
					"Your password has been updated",
					FaLock,
					new ToastConfig({
						iconColour: "success",
						headerColour: "success",
						borderColour: "success",
					})
				);
			},
			error(err) {
				errorState.emit(err.error);
			},
		});
	};

	return (
		<>
			<div className="p-2">
				<div className="mb-4">
					<StatefullInput
						stateId="currentPassword"
						label="Current Password"
						defaultValue={currentPassword}
						inputType="password"
						autocompleteType="password"
						onChangeCallback={(val: string) => updateCurrentPassword(val)}
						onPressCallback={(e: any) => {}}
						stateChangeEvent={errorState}
					/>
				</div>

				<div className="mb-1">
					<StatefullInput
						stateId="password"
						label="Password"
						defaultValue={password}
						inputType="password"
						autocompleteType="new-password"
						onChangeCallback={(val: string) => updatePassword(val)}
						onPressCallback={(e: any) => {}}
						stateChangeEvent={errorState}
					/>
				</div>

				<div className="mb-3">
					<StatefullInput
						stateId="repeatPassword"
						label="Repeat Password"
						defaultValue={passwordRepeat}
						inputType="password"
						autocompleteType="new-password"
						onChangeCallback={(val: string) => updatePasswordRepeat(val)}
						onPressCallback={(e: any) => {}}
						stateChangeEvent={errorState}
					/>
				</div>
				<StatefullLabel stateId="global" stateChangeEvent={errorState} />
				<button
					disabled={currentPassword === "" || password === "" || password === undefined || password !== passwordRepeat}
					onClick={() => makeChangeRequest()}
					className="btn btn-outline-primary w-100"
				>
					Update Password
				</button>
			</div>
		</>
	);
}
