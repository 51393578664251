import React from "react";
import { Nav } from "react-bootstrap";
import { FaBoltLightning, FaCircleInfo } from "react-icons/fa6";
import "./documentation-page.scss";
export default function DocumentationPage() {
	return (
		<div style={{ maxWidth: "800px" }} className="w-100 m-auto mb-5">
			<div>
				<h3>
					<FaBoltLightning className="text-warning me-2 h2" />
					<span className="h2 number">Welcome</span>
				</h3>
				<div className="card overflow-hidden mt-2 p-3 mb-3">
					<p className="">Hello! Here's what you need to get started.</p>

					<div className="rounded overflow-hidden mb-3">
						<div className="bg-light border-start border-warning border-5 p-2 d-flex align-items-center justify-content-start gap-2">
							<FaCircleInfo className="mb-0 h5" />
							<div>
								<strong>Important:</strong>{" "}
								<span>All requests should be made from an API. Keep your application API KEY a secret at all times.</span>
							</div>
						</div>
					</div>

					<div>
						<strong className="fs-5 bold">API Reference</strong>
						<p className="mb-0">This is your go-to for all the technical details.</p>
						<p>Select an endpoint below to get the complete integration documentation to get started.</p>
						<div className="d-flex align-items-center justify-content-start gap-2">
							<Nav.Link href="#identify">
								<span className="fs-6 bg-light p-2 rounded">/identify</span>
							</Nav.Link>

							<Nav.Link href="#announcements">
								<span className="fs-6 bg-light p-2 rounded">/announcements</span>
							</Nav.Link>
						</div>
					</div>
				</div>

				<div className="card overflow-hidden mt-2 p-3 mb-5" id="identify">
					<h3>
						<strong className="fs-5 bold">/identify</strong>
					</h3>
					<p>
						The identify endpoint lets you add and update customers and their key-value properties to an application. This endpoint is
						required to onboard users before announcements are available.
					</p>
					<p>
						You can provide as many or as few properties as you want when identifying a customer. The miniumum required is the users
						persisted unique identifier. More information can be provided through the name property and metadata fields.
					</p>

					<div className="shadow-sm border rounded p-3 mt-2">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<div className="bg-success text-white rounded-pill py-1 px-3 me-2">
								<small className="smaller">POST</small>
							</div>
							<span>
								https://www.myannouncements.com/api/v1<strong>/customers/identify</strong>
							</span>
						</div>

						<div>
							<p>
								<span>Add or update a customer profile</span>
							</p>
							<p className="fw-bold">Parameters</p>
							<div>
								<p className="fw-bold mb-0">Header</p>
								<div className="d-flex align-items-center justify-content-between gap-3 border-bottom py-2">
									<span className="flex-fill-even">Content-Type</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">application/json</span>
								</div>
								<div className="d-flex align-items-center justify-content-between gap-3 py-2">
									<span className="flex-fill-even">x-api-key</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">&lt;APPLICATION API KEY&gt;</span>
								</div>
							</div>

							<div className="mt-3">
								<p className="fw-bold mb-0">Body</p>
								<div className="d-flex align-items-center justify-content-between gap-3 border-bottom py-2">
									<span className="flex-fill-even text-danger">userId *</span>
									<span className="flex-fill-even text-danger">String</span>
									<span className="flex-fill-even text-danger">User Id</span>
								</div>
								<div className="d-flex align-items-center justify-content-between border-bottom gap-3 py-2">
									<span className="flex-fill-even">name</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">User Name</span>
								</div>
								<div className="d-flex align-items-center justify-content-between border-bottom gap-3 py-2">
									<span className="flex-fill-even">groupId</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">(Advanced Tier and above)</span>
								</div>

								<div className="d-flex align-items-center justify-content-between gap-3 py-2">
									<span className="flex-fill-even">metaData</span>
									<span className="flex-fill-even">Key-Value</span>
									<span className="flex-fill-even">User Property JSON</span>
								</div>
							</div>

							<div className="mt-3">
								<p className="fw-bold mb-0">Responses</p>
								<div className="d-flex align-items-center justify-content-between py-2">
									<div className="p-2 rounded-circle bg-success me-1"></div>
									<span className="flex-fill-even number">200: OK</span>
								</div>
							</div>
						</div>
					</div>

					<div className="rounded overflow-hidden mt-3">
						<div className="bg-light border-start border-info border-5 p-2 d-flex align-items-center justify-content-start gap-2">
							<FaCircleInfo className="mb-0 h5" />
							<div>
								<strong>Important:</strong> <span>Fields in </span>
								<span className="text-danger fw-bold">red</span> <span>are required in your request!</span>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<h3>
							<strong className="fs-5 bold">Usage</strong>
						</h3>
						<p>
							It's important to remember that property keys override each other. For example, adding a 'plan' property and starting with
							the value 'Free'; the next time the identify request is made, if the 'plan' key is ommited or changed the change is
							reflected on the stored user.
						</p>

						<div className="bg-light rounded p-2 mb-3">
							<pre className="mb-0">
								<code>
									{JSON.stringify(
										{
											userId: "00000000-0000-0000-0000-000000000000",
											name: "John Doe",
											groupId: "MA Team",
											metaData: {
												email: "info@myannouncements.com",
												plan: "Premium",
												signUpDate: new Date(),
											},
										},
										undefined,
										4
									)}
								</code>
							</pre>
						</div>

						<h3>
							<strong className="fs-5 bold">Property Schema</strong>
						</h3>
						<p>
							Note that properties in the body must be valid JSON format, dont include special characters, numbers as key names. Maximum
							JSON body is <span className="number">500</span> Bytes
						</p>
					</div>
				</div>

				<div className="card overflow-hidden mt-2 p-3 mb-3" id="announcements">
					<h3>
						<strong className="fs-5 bold">/announcements</strong>
					</h3>
					<p>
						The identify endpoint lets you add and update customers and their key-value properties to an application. This endpoint is
						required to onboard users before announcements are available.
					</p>
					<p>
						You can provide as many or as few properties as you want when identifying a customer. The miniumum required is the users
						persisted unique identifier. More information can be provided through the name property and metadata fields.
					</p>

					<div className="shadow-sm border rounded p-3 mt-2">
						<div className="d-flex align-items-center justify-content-start mb-3">
							<div className="bg-primary text-white rounded-pill py-1 px-3 me-2">
								<small className="smaller">GET</small>
							</div>
							<span>
								https://www.myannouncements.com/api/v1<strong>/announcements/user/&#123;USERID&#125;</strong>
							</span>
						</div>

						<div>
							<p>
								<span>Get announcements for the given user</span>
							</p>
							<p className="fw-bold">Parameters</p>
							<div>
								<p className="fw-bold mb-0">Header</p>
								<div className="d-flex align-items-center justify-content-between gap-3 border-bottom py-2">
									<span className="flex-fill-even">Content-Type</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">application/json</span>
								</div>
								<div className="d-flex align-items-center justify-content-between gap-3 py-2">
									<span className="flex-fill-even">x-api-key</span>
									<span className="flex-fill-even">String</span>
									<span className="flex-fill-even">&lt;APPLICATION API KEY&gt;</span>
								</div>
							</div>

							<div className="mt-3">
								<p className="fw-bold mb-0">Responses</p>

								<div className="d-flex align-items-center justify-content-between gap-3 border-bottom py-2">
									<div className="flex-fill-even">
										<div className="d-flex align-items-center justify-content-start">
											<div className="p-2 rounded-circle bg-success me-1 w-content"></div>
											<span className="flex-fill-even number">200: OK</span>
										</div>
									</div>
									<span className="flex-fill-even">Array</span>
									<span className="flex-fill-even">Array [Announcement]</span>
								</div>

								<div className="d-flex align-items-center justify-content-between gap-3 py-2">
									<div className="flex-fill-even">
										<div className="d-flex align-items-center justify-content-start">
											<div className="p-2 rounded-circle bg-danger me-1 w-content"></div>
											<span className="flex-fill-even number">400: Bad Request</span>
										</div>
									</div>
									<span className="flex-fill-even">Object</span>
									<span className="flex-fill-even">Error Message</span>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<h3>
							<strong className="fs-5 bold">Announcement Entity</strong>
						</h3>
						<p>
							The announcement entity is what we return in your API call when fetching announcements for a user, we return an array
							ordered by the announcement creation time first.
						</p>

						<div className="bg-light rounded p-2 mb-3">
							<pre className="mb-0">
								<code>
									{JSON.stringify(
										{
											id: "string",
											status: "INFO | WARNING | ALERT",
											message: "string",
											date: new Date(),
											read: "boolean",
										},
										undefined,
										4
									)}
								</code>
							</pre>
						</div>

						<div className="rounded overflow-hidden mt-3">
							<div className="bg-light border-start border-info border-5 p-2 d-flex align-items-center justify-content-start gap-2">
								<FaCircleInfo className="mb-0 h5" />
								<div>
									<strong>Important:</strong>{" "}
									<span>The 'read' property is to show an indication based on if the user has seen this message previously.</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
