import { IconType } from "react-icons/lib";

export class ToastConfig {
	public autoHide: boolean = false;
	public iconColour: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" = "dark";
	public headerColour: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" = "dark";
	public borderColour: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" = "light";
	public duration: number = 3000;

	public constructor(init?: Partial<ToastConfig>) {
		Object.assign(this, init);
	}

	public success(): ToastConfig {
		this.iconColour = "success";
		this.headerColour = "success";
		this.borderColour = "success";
		return this;
	}

	public warning(): ToastConfig {
		this.iconColour = "warning";
		this.headerColour = "warning";
		this.borderColour = "warning";
		return this;
	}

	public danger(): ToastConfig {
		this.iconColour = "danger";
		this.headerColour = "danger";
		this.borderColour = "danger";
		return this;
	}

	public hide(duration: number): ToastConfig {
		this.autoHide = true;
		this.duration = duration;
		return this;
	}
}

export default class IToast {
	constructor(
		public id: string,
		public index: number,
		public icon: IconType,
		public header: string,
		public content: string,
		public config: ToastConfig,
		public active: boolean = false,

		public close: () => void
	) {}
}
