import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ConfirmationModal, { ConfirmationOptions } from "../../components/confirmation-modal/confirmation-modal";
import InvoiceListModal from "../../components/invoice-list-modal/invoice-list-modal";
import InvoiceModal from "../../components/invoice-modal/invoice-modal";
import Invoice from "../../models/data/invoice";
import IModal from "../../models/interfaces/IModal";
import EventEmitter from "../event/event-emitter";

function ModalServiceComponent() {
	const [modals, setModals] = useState<IModal[]>([]);

	useEffect(() => {
		setModals([]);

		var modalSub = ModalService.ModalChangeEvent.subscribe((_: IModal[]) => {
			setModals([..._]);
		});

		return () => {
			ModalService.ModalChangeEvent.unsubscribe(modalSub);
		};
	}, []);

	const MODAL_HTML = (key: number, id: string, modal: IModal, modalCount: number) => {
		return (
			<div key={key} className="modal_container">
				<Modal
					show={modal.active}
					onHide={() => modal.close()}
					size={modal.size}
					centered={true}
					backdrop={true}
					className={`${key.toString()}_${modalCount.toString()}_depth_modal ${modalCount === key ? "d-block" : "d-none"}`}
				>
					<div>{modal.content}</div>
				</Modal>
			</div>
		);
	};

	const MODAL_PARENT = useCallback(() => {
		return (
			<>
				<div id="root_modal_parent">
					{modals.map((m: IModal, i: number) => {
						if (m.active) return MODAL_HTML(i, `modal_${i}`, m, modals.length - 1);
						else return null;
					})}
				</div>
			</>
		);
	}, [modals]);

	return <>{MODAL_PARENT()}</>;
}

// SERVICE STUFF

var MODALS: IModal[] = [];
var modalChangeEvent: EventEmitter<IModal[]> = new EventEmitter<IModal[]>();

function _OpenModal(id: string, renderComponent: JSX.Element, size?: "sm" | "lg" | "xl" | undefined): IModal | undefined {
	var existingIndex = MODALS.findIndex((_) => _.id === id);
	if (existingIndex > -1) {
		_CloseModalById(id);
	}

	var newModal = new IModal(
		id,
		MODALS.length + 1,
		renderComponent,
		true,
		() => {
			_CloseModalById(id);
		},
		size
	);

	var tempModals = [...MODALS, newModal];
	MODALS = tempModals;

	modalChangeEvent.emit(MODALS);

	if (newModal == null) console.error("Failed to open modal");

	return newModal;
}

function OpenConfirmation(
	id: string,
	header: string,
	body: string,
	confirmText: string,
	declineText: string,
	callback: (_: boolean) => void,
	size: "sm" | "lg" | "xl" | undefined = undefined
): IModal | undefined {
	return _OpenModal(
		id,
		<ConfirmationModal modalId={id} options={new ConfirmationOptions(header, body, confirmText, declineText)} callback={callback} />,
		size
	);
}

function OpenInvoiceList(id: string, invoices: Invoice[], size: "sm" | "lg" | "xl" | undefined = undefined): IModal | undefined {
	return _OpenModal(id, <InvoiceListModal modalId={id} invoices={invoices} />, size);
}

function OpenInvoice(
	id: string,
	invoice: Invoice,
	withConfirmation: boolean,
	callback: (_: boolean) => void,
	size: "sm" | "lg" | "xl" | undefined = undefined
): IModal | undefined {
	return _OpenModal(id, <InvoiceModal modalId={id} invoice={invoice} includeConfirmation={withConfirmation} callback={callback} />, size);
}

function _CloseModalById(id: string): void {
	var existingIndex = MODALS.findIndex((_) => _.id.toString() === id.toString());
	if (existingIndex === -1) {
		return;
	}
	MODALS[existingIndex].onClose.emit();
	MODALS.splice(existingIndex, 1);
	modalChangeEvent.emit(MODALS);
}

const ModalService = {
	Component: ModalServiceComponent,
	ModalChangeEvent: modalChangeEvent,
	OpenConfirmation,
	OpenInvoice,
	OpenInvoiceList,
	OpenModal: _OpenModal,
	CloseModal: _CloseModalById,
};

export default ModalService;
