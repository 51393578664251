import React, { useCallback, useEffect, useState } from "react";
import { FaChevronRight, FaCircleUser, FaUsers } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import RelativeTime from "../../misc/helpers/relative-time";
import Announcement from "../../models/data/announcement";
import AnnouncementService from "../../services/announcement-service";
import "./announcement-list.scss";
export default function AnnouncementList() {
	const [announcements, setAnnouncements] = useState<Announcement[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		AnnouncementService.GetAnnouncements({
			success(_) {
				setAnnouncements(_);
			},
			error(err) {},
		});
	}, []);

	const openAnnouncementCallback = useCallback(
		(announcement: Announcement) => {
			navigate(`/dashboard/announcement-detail/${announcement.id}`);
		},
		[navigate]
	);

	const IsAnnouncementAvailable = (announcement: Announcement) => {
		// from set
		if (announcement.availableFrom !== null) {
			// both set
			if (
				announcement.availableTo !== null &&
				new Date().getTime() > new Date(announcement.availableFrom).getTime() &&
				new Date().getTime() < new Date(announcement.availableTo).getTime()
			)
				return "list-group-item-success";

			// to unset
			if (announcement.availableTo === null) {
				if (new Date().getTime() < new Date(announcement.availableFrom).getTime()) return "list-group-item-warning";
				return "list-group-item-white";
			}

			// to set
			if (announcement.availableTo !== null) {
				if (new Date().getTime() > new Date(announcement.availableTo).getTime()) return "list-group-item-danger";
			}

			return "list-group-item-warning";
		}

		// from unset
		if (announcement.availableTo !== null) {
			if (new Date().getTime() > new Date(announcement.availableTo).getTime()) return "list-group-item-danger";
		}

		// default
		return "list-group-item-white";
	};

	return (
		<div className="">
			{announcements.map((announcement: Announcement, index: number) => {
				return (
					<div
						key={announcement.id}
						className={`interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item ${IsAnnouncementAvailable(
							announcement
						)}`}
						onClick={() => openAnnouncementCallback(announcement)}
					>
						<div
							className={`p-1 rounded-circle text-light ${
								announcement.status === "INFO" ? "bg-success" : announcement.status === "WARNING" ? "bg-warning" : "bg-danger"
							}`}
						>
							{announcement.type === "USER" ? <FaCircleUser className="h3 mb-0" /> : <FaUsers className="h3 mb-0" />}
						</div>
						<span className="mb-0 flex-fill ms-3">{announcement.message}</span>
						<span className="mx-4 number">{RelativeTime(announcement.date)}</span>
						<FaChevronRight className="h5 mb-0" />
					</div>
				);
			})}
		</div>
	);
}
