import React from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import AnnouncementList from "../../components/announcement-list/announcement-list";
import "./dashboard-landing.scss";
export default function DashboardLanding() {
	return (
		<div>
			<div>
				<div className="d-flex align-items-center justify-content-between mx-1">
					<h4 className="mb-0">Published Announcements</h4>
					<div className="flex-fill"></div>
					<div className="d-none d-lg-flex align-items-center justify-content-between gap-3">
						<div className="number d-flex align-items-center justify-content-between px-2 py-1 bg-white rounded-pill text-black border border-secondary">
							<span>Active</span>
						</div>

						<div className="number d-flex align-items-center justify-content-between px-2 py-1 list-group-item-success text-black rounded-pill border border-success">
							<span>Active until expiry</span>
						</div>

						<div className="number d-flex align-items-center justify-content-between px-2 py-1 list-group-item-danger text-black rounded-pill border border-danger">
							<span>Expired</span>
						</div>

						<div className="number d-flex align-items-center justify-content-between px-2 py-1 list-group-item-warning text-black rounded-pill border border-warning">
							<span>Pending</span>
						</div>
					</div>
				</div>
				<div className="card overflow-hidden mt-2">
					<AnnouncementList />
				</div>
			</div>

			<div className="position-absolute bottom-0 end-0 p-3">
				<Link
					to={"/dashboard/create-announcement"}
					className="d-flex align-items-center btn rounded-circle bg-primary text-white"
					style={{ width: "50px", height: "50px" }}
				>
					<FaPlus className="m-0 h4" />
				</Link>
			</div>
		</div>
	);
}
