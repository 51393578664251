import { HTTP_CONSTANTS } from "../constants/http-constants";
import Domain from "../models/data/domain";
import UserAccount from "../models/data/user-account";
import ApplicationCreateRequest from "../models/requests/ApplicationCreateRequest";
import EventEmitter from "./event/event-emitter";
import HttpService, { HttpError, ResponseCallback } from "./http/http-service";

var selectedDomain: Domain | undefined = InitSelectedDomain();
var currentDomains: Domain[] = [];
var domainUser: UserAccount | undefined = undefined;
const DomainsFetched: EventEmitter<Domain[]> = new EventEmitter<Domain[]>();
const DomainChange: EventEmitter<Domain> = new EventEmitter<Domain>();

function InitSelectedDomain(): Domain | undefined {
	var storedDomain = localStorage.getItem("selected-domain");
	if (storedDomain === null) return undefined;
	return JSON.parse(storedDomain);
}

function RefetchDomains(): void {
	GetDomains({
		success: function (_: Domain[]): void {},
		error: function (err: HttpError): void {},
	});
}

function GetDomains(callback: ResponseCallback<Domain[]>): void {
	HttpService.get<Domain[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/domain`,
		(res: Domain[]) => {
			currentDomains = res;
			if (selectedDomain !== undefined) {
				if (currentDomains.filter((cd) => cd.id === selectedDomain?.id).length === 0) {
					if (res.length > 0) SetSelectedDomain(res[0]);
				}
			}
			DomainsFetched.emit(res);
			callback.success(res);
		},
		callback.error,
		callback.always
	);
}

function GetDomainUserDetails(callback: ResponseCallback<UserAccount>): void {
	if (selectedDomain === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<UserAccount>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/domain/${selectedDomain.id}/account`,
		(_) => {
			domainUser = _;
			callback.success(_);
		},
		callback.error,
		callback.always
	);
}

function CreateApplication(applicationCreateRequest: ApplicationCreateRequest, callback: ResponseCallback<boolean>): void {
	HttpService.post<ApplicationCreateRequest, { status: boolean }>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/domain/create`,
		applicationCreateRequest,
		(res: { status: boolean }) => {
			callback.success(res.status);
		},
		callback.error
	);
}

function SetSelectedDomain(domain: Domain): void {
	selectedDomain = domain;
	DomainChange.emit(selectedDomain);
	localStorage.setItem("selected-domain", JSON.stringify(selectedDomain));
}

function SelectedDomain(): Domain | undefined {
	return selectedDomain;
}

function CurrentDomains(): Domain[] {
	return currentDomains;
}

function DomainUser(): UserAccount | undefined {
	return domainUser;
}

const DomainService = {
	DomainChange,
	DomainsFetched,

	SetSelectedDomain,
	SelectedDomain,
	DomainUser,
	CurrentDomains,
	RefetchDomains,
	GetDomains,
	CreateApplication,
	GetDomainUserDetails,
};

export default DomainService;
