import React, { useCallback, useState } from "react";
import { FaCheck, FaX } from "react-icons/fa6";
import Capitalise, { DomainFromEmail } from "../../misc/helpers/text-format";
import UserSession from "../../models/data/user-session";
import { ToastConfig } from "../../models/interfaces/IToast";
import InviteRequest from "../../models/requests/InviteRequest";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import UserService from "../../services/session/user-service";
import SettingService from "../../services/setting-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import ConfirmationModal, { ConfirmationOptions } from "../confirmation-modal/confirmation-modal";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";
import StatefullInput from "../statefull-input/statefull-input";
import StatefullLabel from "../statefull-label/statefull-label";
import "./account-invite-modal.scss";

export default function AccountInviteModal({ modalId }: { modalId: string }) {
	const [inviteRequest, setInviteRequest] = useState<InviteRequest>({
		email: "",
		role: "standard",
	});
	const [activeSession] = useState<UserSession | undefined>(UserService.getCurrentSession());
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const SendRequest = useCallback(() => {
		SettingService.InviteUser(inviteRequest, {
			success: (_: any) => {
				ModalService.CloseModal(modalId);
				ToastService.OpenToast(
					"invite_complete",
					"Invite Success",
					"Account has been added to the domain",
					FaCheck,
					new ToastConfig().success().hide(3000)
				);
			},
			error: (err: HttpError) => {
				errorState.emit(err.error);
				ToastService.OpenToast("invite_fail", "Invite Failed", "Account failed to be added.", FaX, new ToastConfig().danger().hide(3000));
			},
		});
	}, [errorState, inviteRequest, modalId]);

	const MismatchDomain = useCallback(() => {
		ModalService.OpenModal(
			"mismatch_domains",
			<ConfirmationModal
				modalId={"mismatch_domains"}
				options={
					new ConfirmationOptions(
						"Mismatch domain",
						`The account you are trying to add does not share your email domain and might be an incorrect account, are you sure '${inviteRequest.email}' is correct?`,
						"Yes I'm sure",
						"Cancel"
					)
				}
				callback={function (_: boolean): void {
					if (_) SendRequest();
				}}
			/>,
			"lg"
		);
	}, [SendRequest, inviteRequest]);

	const SubmitInviteRequest = useCallback(() => {
		if (activeSession === undefined) return;
		if (
			DomainFromEmail(activeSession.email) !== activeSession.email &&
			DomainFromEmail(activeSession.email) !== DomainFromEmail(inviteRequest.email)
		) {
			MismatchDomain();
		} else {
			SendRequest();
		}
	}, [MismatchDomain, SendRequest, activeSession, inviteRequest]);

	return (
		<div className="p-3">
			<h4 className="mb-0">Invite a User</h4>
			<p className="mb-0">Invite a user to manage this site.</p>
			<div className="my-2 border-top"></div>
			<div className="mt-2">
				<StatefullInput
					stateId={"invite_email"}
					defaultValue={inviteRequest.email}
					inputType={"email"}
					autocompleteType={"email"}
					placeholder={"Email"}
					label={"Account Email"}
					stateChangeEvent={errorState}
					onChangeCallback={(val: string) => {
						setInviteRequest({ ...inviteRequest, email: val });
					}}
				/>
			</div>
			<div className="mt-2">
				<SelectableDropdown
					stateId={"invite_role"}
					options={["Standard", "Admin"]}
					selectedOption={Capitalise(inviteRequest.role)}
					label={"Account Role"}
					stateChangeEvent={errorState}
					onChange={function (val: "Admin" | "Standard"): void {
						setInviteRequest({ ...inviteRequest, role: val === "Admin" ? "admin" : "standard" });
					}}
				/>
			</div>

			<div className="mt-2 text-primary">
				{inviteRequest.role === "admin" ? (
					<small className="d-block mb-0">
						Admin users have all permissions including changing site settings, billing access & user management.
					</small>
				) : (
					<small className="d-block mb-0">
						Standard users have permission to view and create announcements as well as customer profiles.
					</small>
				)}
			</div>

			<StatefullLabel stateId={"global"} stateChangeEvent={errorState} />

			<div className="border-top my-2"></div>
			<div className="d-flex align-items-center justify-content-end gap-2">
				<button
					className="btn btn-outline-danger"
					onClick={() => {
						ModalService.CloseModal(modalId);
					}}
				>
					Cancel
				</button>
				<button
					className="btn btn-primary"
					onClick={() => {
						SubmitInviteRequest();
					}}
				>
					Submit
				</button>
			</div>
		</div>
	);
}
