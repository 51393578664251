import React, { useState } from "react";
import { APP_TIERS } from "../../App";
import { SubscriptionTier, SubscriptionTierMapper } from "../../models/data/subscription-tier";
import ModalService from "../../services/ui/modal-service";
import PricingCard from "../pricing-card/pricing-card";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";
import "./subscription-select-modal.scss";
export default function SubscriptionSelectModal({
	modalId,
	startTier = SubscriptionTier.ESSENTIAL,
	confirmCallback,
}: {
	modalId: string;
	startTier?: SubscriptionTier;
	confirmCallback: (_: SubscriptionTier) => void;
}) {
	const [selectedTier, setSelectedTier] = useState<SubscriptionTier>(startTier);

	const SelectOption = () => {
		confirmCallback(selectedTier);
	};

	return (
		<div className="p-3">
			<div className="mb-2">
				<h3 className="mb-0">Select Subscription Tier</h3>
				<small className="text-muted">This can be changed at any time.</small>
			</div>
			<div>
				<SelectableDropdown
					stateId={"subscription_tier"}
					options={["ESSENTIAL", "PREMIUM", "ADVANCED", "ENTERPRISE"]}
					selectedOption={SubscriptionTierMapper.FromTier(selectedTier)}
					onChange={(val: string) => {
						setSelectedTier(SubscriptionTierMapper.ToTier(val));
					}}
				/>
			</div>

			<div className="mt-3">
				<PricingCard tier={APP_TIERS[SubscriptionTierMapper.FromTier(selectedTier)]} interactable={false} showButtons={false} />
			</div>

			<div className="d-flex justify-content-end align-items-center gap-2 mt-3">
				<button
					onClick={() => {
						ModalService.CloseModal(modalId);
					}}
					className="btn btn-outline-danger"
				>
					Cancel
				</button>
				<button onClick={() => SelectOption()} className="btn btn-primary">
					Continue
				</button>
			</div>
		</div>
	);
}
