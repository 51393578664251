import React, { useCallback } from "react";
import { FaArrowRightFromBracket, FaCircleUser, FaGear } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { NavigationRoute } from "../../pages/dashboard-page";
import UserService from "../../services/session/user-service";
import DomainSelector from "../domain-selector/domain-selector";
import "./dashboard-header.scss";
export default function DashboardHeader({ currentRoute }: { currentRoute?: NavigationRoute }) {
	const navigate = useNavigate();

	const LogOut = useCallback(() => {
		UserService.logout(() => {
			navigate("/auth/login");
		});
	}, [navigate]);

	return (
		<div className="w-100 px-1 px-md-3 pe-1 py-3 bg-white border-bottom">
			<div className="d-flex justify-content-start align-items-center">
				<h2 className="mb-0 d-none d-md-block">{currentRoute?.routeName}</h2>
				<div className="flex-fill"></div>
				{/* account select */}
				<div>
					<DomainSelector />
				</div>
				<div className="dropdown">
					<button
						className="btn btn-link btn-icon dropdown-selector"
						type="button"
						id="AccountDropdownButton"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<FaCircleUser className="h1 m-0" />
					</button>
					<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="AccountDropdownButton">
						<li>
							<button
								className="dropdown-item d-flex justify-content-between align-items-center"
								onClick={() => navigate("/dashboard/account-settings")}
							>
								Account
								<FaGear className="h4 m-0" />
							</button>
							<button className="dropdown-item d-flex justify-content-between align-items-center" onClick={() => LogOut()}>
								Sign Out
								<FaArrowRightFromBracket className="h4 m-0" />
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
