import { HTTP_CONSTANTS } from "../constants/http-constants";
import UserAnnouncement from "../models/data/user-announcement";
import HttpService, { ResponseCallback } from "./http/http-service";

function IdentifyCustomer(): void {
	HttpService.get<any>(`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/application/identify`);
}

function GetAnnouncements(callback: ResponseCallback<UserAnnouncement[]>): void {
	HttpService.get<UserAnnouncement[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/application/announcements`,
		callback.success,
		callback.error,
		callback.always
	);
}

const ImplementationService = {
	IdentifyCustomer,
	GetAnnouncements,
};

export default ImplementationService;
