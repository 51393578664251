import React, { useCallback } from "react";
import AppAnnouncements from "../../components/app-announcements/app-announcements";
import CreateApplicationModal from "../../components/create-application-modal/create-application-modal";
import DomainService from "../../services/domain-service";
import ModalService from "../../services/ui/modal-service";
import "./dashboard-not-configured.scss";
export default function DashboardNotConfigured() {
	const StartCreateHandler = useCallback(() => {
		var m = ModalService.OpenModal("create_application_modal", <CreateApplicationModal modalId="create_application_modal" />);
		if (m === undefined) return; //
		m.onClose.subscribe(() => {
			DomainService.GetDomains({
				success(_) {},
				error(err) {},
			});
		});
	}, []);

	return (
		<>
			<div className="mb-5">
				<h2 className="text-center m-auto mt-4 mb-3">Welcome!</h2>
				<p style={{ maxWidth: "400px" }} className="text-center m-auto">
					Your account is all ready, we just need to get you set-up with a application to manage!
				</p>

				<div className="d-flex justify-content-center align-items-center gap-2 mt-4">
					<button onClick={() => StartCreateHandler()} className="btn btn-primary">
						Create New Application
					</button>
				</div>
				<div className="mt-3">
					<p style={{ maxWidth: "500px" }} className="m-auto text-muted text-center">
						You can be added to an existing application by an administrator using the email you signed up with under the 'Settings' tab!
					</p>
				</div>
			</div>

			<div className="mb-5">
				<h4 className="mb-0 mx-1">App Announcements</h4>
				<div className="card overflow-hidden mt-2">
					<AppAnnouncements />
				</div>
			</div>
		</>
	);
}
