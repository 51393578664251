import React, { useState } from "react";
import { FaFrown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ToastConfig } from "../../models/interfaces/IToast";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import UserService from "../../services/session/user-service";
import ToastService from "../../services/ui/toast-service";
import StatefullInput from "../statefull-input/statefull-input";
import "./deactivate-account-component.scss";
export default function DeactivateAccountComponent() {
	const [email, updateEmail] = useState("");
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
	const navigate = useNavigate();

	const keyDownHandler = (event: KeyboardEvent) => {
		if (event.key === "Enter") {
			event.preventDefault();
			MakeDeleteRequest();
		}
	};

	const MakeDeleteRequest = () => {
		UserService.deactivate(
			{ email: email },
			{
				success(_) {
					ToastService.OpenToast(
						"account_deactivated",
						"Account Deactivated",
						"Your account has been deactivated and all associated data has been deleted.",
						FaFrown,
						new ToastConfig().danger()
					);
					navigate("/auth/register");
				},
				error(err: HttpError) {
					errorState.emit(err.error);
				},
			}
		);
	};

	return (
		<div className="p-2">
			<div className="d-block w-100">
				<p className="mb-2">Doing this will delete all data & subscriptions, this can not be reversed.</p>
				<p>Deactivating your account will not re-enable previously redeemed access codes.</p>
				<div className="mb-3">
					<StatefullInput
						stateId="email"
						label="Enter your email to confirm"
						placeholder="E-mail"
						defaultValue={email}
						inputType="text"
						autocompleteType="email"
						onChangeCallback={(val: string) => updateEmail(val)}
						onPressCallback={(e: any) => keyDownHandler(e)}
						stateChangeEvent={errorState}
					/>
				</div>

				<button onClick={MakeDeleteRequest} className="btn btn-danger w-100">
					Deactivate account
				</button>
			</div>
		</div>
	);
}
