import React, { useCallback, useEffect, useState } from "react";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import "./statefull-input.scss";
export default function StatefullInput({
	stateId,
	label,
	placeholder = "",
	defaultValue,
	inputType,
	autocompleteType,
	onChangeCallback,
	onPressCallback,
	stateChangeEvent,
	resetEvent = new EventEmitter<void>(),
	resetValidation = new EventEmitter<void>(),
	dirtyEvent = new EventEmitter<void>(),
	readonly = false,
	required = false,
	loading = false,
	useValidation = true,
	showErrors = true,
}: {
	stateId: string;
	label?: string;
	placeholder?: string;
	defaultValue: string;
	inputType: "text" | "date" | "textarea" | "email" | "hidden" | "password" | "tel" | "url";
	autocompleteType: string;
	onChangeCallback?: (_: string) => void;
	onPressCallback?: (_: any) => void;
	stateChangeEvent?: EventEmitter<IErrorResp>;
	resetEvent?: EventEmitter<void>;
	resetValidation?: EventEmitter<void>;
	dirtyEvent?: EventEmitter<void>;
	readonly?: boolean;
	required?: boolean;
	loading?: boolean;
	useValidation?: boolean;
	showErrors?: boolean;
}) {
	const [value, setValue] = useState(defaultValue || "");
	const [forceError, setForceError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	const validate = useCallback(
		(errorResp: IErrorResp) => {
			setForceError(false);
			setIsDirty(true);
			setErrorMessage("");
			if (errorResp !== undefined && errorResp !== null && errorResp.fixes != null) {
				errorResp.fixes.forEach((fix: IErrorFix) => {
					if (fix.key === stateId) {
						setErrorMessage(fix.value);
					}

					if (fix.key === "global") setForceError(true);
				});
			}
		},
		[stateId]
	);

	const clearValidation = useCallback(() => {
		setForceError(false);
		setIsDirty(false);
		setErrorMessage("");
	}, []);

	useEffect(() => {
		if (onChangeCallback === undefined) return;
		onChangeCallback(value);
		// eslint-disable-next-line
	}, [value]);

	// update value if default value changes
	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		const resetSub = resetEvent.subscribe(() => {
			setValue(defaultValue);
		});
		const resetValidationSub = resetValidation.subscribe(() => {
			clearValidation();
		});
		if (stateChangeEvent !== undefined) {
			const stateSubscription = stateChangeEvent.subscribe((_: IErrorResp) => {
				validate(_);
			});

			return () => {
				stateChangeEvent.unsubscribe(stateSubscription);
				resetEvent.unsubscribe(resetSub);
				resetValidation.unsubscribe(resetValidationSub);
			};
		}
	}, [stateId, stateChangeEvent, validate, defaultValue, resetEvent, resetValidation, clearValidation]);

	const validStateClass = useCallback(() => {
		if (!useValidation) return "";
		if (loading) return "";
		if (readonly) return "";
		if (forceError) return "is-invalid";
		if (!isDirty) return "";
		if (isDirty && errorMessage === "") return "is-valid";
		return "is-invalid";
	}, [errorMessage, forceError, isDirty, loading, readonly, useValidation]);

	const errorElement = useCallback(() => {
		return errorMessage ? <label className="mt-1 d-block form-label text-danger small">{errorMessage}</label> : null;
	}, [errorMessage]);

	return (
		<div>
			{label === undefined ? null : (
				<label htmlFor={`${stateId}Input`} className="form-label mb-0">
					{label}
				</label>
			)}
			<div className="position-relative">
				{inputType === "textarea" ? (
					<textarea
						rows={3}
						required={required}
						readOnly={readonly}
						disabled={readonly}
						placeholder={placeholder}
						autoComplete={autocompleteType}
						id={`${stateId}Input`}
						className={`form-control ${validStateClass()}`}
						value={value}
						onChange={(event) => {
							setValue(event.target.value);
							clearValidation();
						}}
						onKeyDown={(e: any) => {
							if (onPressCallback === undefined) return;
							onPressCallback(e);
						}}
						onClick={() => {
							dirtyEvent.emit();
						}}
					></textarea>
				) : (
					<input
						required={required}
						readOnly={readonly}
						disabled={readonly}
						placeholder={placeholder}
						autoComplete={autocompleteType}
						id={`${stateId}Input`}
						type={inputType}
						className={`form-control ${validStateClass()}`}
						value={value}
						onChange={(event) => {
							setValue(event.target.value);
							clearValidation();
						}}
						onKeyDown={(e: any) => {
							if (onPressCallback === undefined) return;
							onPressCallback(e);
						}}
						onClick={() => {
							dirtyEvent.emit();
						}}
					/>
				)}
				{loading ? (
					<div className="position-absolute w-100 d-flex align-items-center justify-content-end top-0 right-0 bottom-0 left-0 px-2">
						<div className="spinner-grow text-primary spinner-grow-sm" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			{showErrors && errorElement()}
		</div>
	);
}
