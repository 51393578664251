import UserSession from "../../models/data/user-session";
import EventEmitter from "../event/event-emitter";

const _sessionUpdatedEvent = new EventEmitter<UserSession>();

const UserSessionService = {
	sessionUpdatedEvent: _sessionUpdatedEvent,
};

export default UserSessionService;
