import { useCallback, useEffect, useState } from "react";
import { FaHouse, FaUsers, FaGear, FaRegFileCode } from "react-icons/fa6";
import { Link, Outlet, useLocation } from "react-router-dom";
import DashboardHeader from "../components/dashboard-header/dashboard-header";
import DashboardNavigation from "../components/dashboard-navigation/dashboard-navigation";
import LoadingOverlay from "../components/loading-overlay/loading-overlay";
import Domain from "../models/data/domain";
import Invoice from "../models/data/invoice";
import UserAccount from "../models/data/user-account";
import BillingService from "../services/billing-service";
import DomainService from "../services/domain-service";
import { HttpError } from "../services/http/http-service";
import ToastService from "../services/ui/toast-service";
import DashboardNotConfigured from "./dashboard-not-configured/dashboard-not-configured";
import SiteNotActivePage from "./site-not-active-page/site-not-active-page";

export class NavigationRoute {
	constructor(public route: string, public routeName: string, public icon: JSX.Element, public childrenRoutes: string[] = []) {}
}

export default function DashboardPage() {
	const [domainAccount, setDomainAccount] = useState<UserAccount>();
	const [navigation, setNavigation] = useState<NavigationRoute[]>([]);
	const [currentRoute, setCurrentRoute] = useState<NavigationRoute>();
	const [currentDomain, setCurrentDomain] = useState<Domain | undefined>(DomainService.SelectedDomain());
	const [loading, setLoading] = useState<boolean>(false);
	const [authed, setAuthed] = useState<boolean>(true);
	const [loaded, setLoaded] = useState<boolean>(false);
	const location = useLocation();

	const BuildNav = (account: UserAccount | undefined) => {
		if (account === undefined) return [];
		const routes = [
			new NavigationRoute("/dashboard", "Dashboard", <FaHouse />, [
				"/dashboard/create-announcement",
				"/dashboard/announcement-detail/",
				"/dashboard/account-settings",
				"/dashboard/order-confirmation/",
				"/dashboard/order-cancel/",
			]),
			new NavigationRoute("/dashboard/customers", "Customers", <FaUsers />, ["/dashboard/customer-detail/"]),
			new NavigationRoute("/dashboard/documentation", "Documentation", <FaRegFileCode />),
		];
		if (account.accountRole === "admin") {
			routes.push(new NavigationRoute("/dashboard/settings", "Settings", <FaGear />, ["/dashboard/account-detail/"]));
		}
		return routes;
	};

	const GetCurrentRoute = (nav: NavigationRoute[], locationPath: string) => {
		if (locationPath.endsWith("/")) locationPath = locationPath.substring(0, locationPath.length - 1);
		return nav.filter((r: NavigationRoute) => {
			return (
				r.route === locationPath ||
				r.childrenRoutes.find((rc: string) => {
					return locationPath.startsWith(rc);
				}) !== undefined
			);
		});
	};

	const RouteGuard = useCallback(() => {
		if (navigation === undefined || navigation.length === 0) return;
		var _currentRoute = GetCurrentRoute(navigation, location.pathname);
		setAuthed(_currentRoute.length !== 0);
		setLoaded(DomainService.CurrentDomains().length === 0 || domainAccount !== undefined);
		setTimeout(() => {
			LoadingOverlay.Hide();
			setLoading(false);
		}, 500);
	}, [location.pathname, navigation, domainAccount]);

	const GetDomainUserDetails = () => {
		setLoading(true);
		DomainService.GetDomainUserDetails({
			success(_) {
				setDomainAccount(_);
			},
			error(err) {
				// this is bad
			},
			always() {
				setTimeout(() => {
					LoadingOverlay.Hide();
					setLoading(false);
				}, 500);
			},
		});
	};

	useEffect(() => {
		var _currentRoute = GetCurrentRoute(navigation, location.pathname);
		if (_currentRoute.length === 0) setCurrentRoute(navigation[0]);
		else setCurrentRoute(_currentRoute[0]);
	}, [location, navigation]);

	useEffect(() => {
		const nav =
			currentDomain === undefined || (currentDomain === undefined && location.pathname === "/dashboard/account")
				? [new NavigationRoute("/dashboard", "Dashboard", <FaHouse />, ["/dashboard/account"])]
				: BuildNav(domainAccount);

		setNavigation(nav);
	}, [currentDomain, location, domainAccount]);

	useEffect(() => {
		DomainService.GetDomains({
			success(_) {},
			error(err) {},
		});

		GetDomainUserDetails();
	}, []);

	useEffect(() => {
		var domainChangeEvent = DomainService.DomainChange.subscribe((newDomain: Domain) => {
			setCurrentDomain(newDomain);
			GetDomainUserDetails();
		});
		return () => {
			DomainService.DomainChange.unsubscribe(domainChangeEvent);
		};
	}, [navigation, location, RouteGuard]);

	useEffect(() => {
		setLoading(true);
		LoadingOverlay.Show();

		RouteGuard();
	}, [RouteGuard, navigation]);

	const pageContent = () => {
		if (loading) return <></>;
		if (loading || !loaded)
			return (
				<div className="d-flex align-items-center justify-content-center text-center h-100">
					<div>
						<p className="text-center mb-1 m-auto">Something went wrong</p>
						<button className="mb-auto btn btn-primary" onClick={() => window.location.reload()}>
							Refresh
						</button>
					</div>
				</div>
			);
		if (currentDomain !== undefined && !currentDomain.enabled) {
			if (location.pathname === "/dashboard/settings") return <Outlet />;
			if (location.pathname === "/dashboard/account-settings") return <Outlet />;
			return <SiteNotActivePage />;
		}
		return <>{currentDomain === undefined && location.pathname !== "/dashboard/account-settings" ? <DashboardNotConfigured /> : <Outlet />}</>;
	};

	const ViewOutstandingInvoice = () => {
		LoadingOverlay.Show();
		BillingService.GetOutstandingInvoice({
			success(_: Invoice) {
				window.open(_.invoice_pdf, "_blank");
			},
			error(err: HttpError) {
				ToastService.ShowRespError("oustanding_inv", err);
			},
			always() {
				LoadingOverlay.Hide();
			},
		});
	};

	return (
		<div className="pg-100 d-flex flex-column page-lock">
			<div className="w-100 flex-fill d-flex">
				<DashboardNavigation navigation={navigation} currentRoute={currentRoute} currentDomain={currentDomain} />
				<div className="bg-light flex-fill page-container">
					<DashboardHeader currentRoute={currentRoute} />
					{authed || !loaded ? (
						<div className="page-content">
							{currentDomain && currentDomain.inArrears ? (
								<div className="bg-warning p-2 text-center">
									<span>Your account has an outstanding invoice, please complete the checkout process.</span>
									<span
										className="text-primary text-decoration-underline ms-2"
										onClick={() => {
											ViewOutstandingInvoice();
										}}
									>
										View invoice
									</span>
								</div>
							) : (
								<></>
							)}
							<div className="p-md-4 p-1 h-100">{pageContent()}</div>
						</div>
					) : (
						<div className="flex-fill h-100 d-flex align-items-center justify-content-center bg-warning">
							<div className="text-center">
								<h4>You dont have access to this page.</h4>
								<Link to={"/dashboard"} className="text-decoration-underline text-primary">
									Back to dashboard.
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
