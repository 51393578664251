import React from "react";
import { APP_TIERS } from "../../App";
import NavBar from "../../components/nav-bar/nav-bar";
import PricingCard from "../../components/pricing-card/pricing-card";
import background from "../../assets/images/Header B.png";

import ls_logo from "../../assets/images/logo_lukestonier.png";
import mdp_logo from "../../assets/images/logo_black.png";

import "./landing-page.scss";
import { Link, useNavigate } from "react-router-dom";
import {
	FaChartSimple,
	FaCircleCheck,
	FaCloudArrowUp,
	FaCodeMerge,
	FaEye,
	FaRegClock,
	FaRegIdBadge,
	FaRobot,
	FaUsers,
	FaUserTag,
} from "react-icons/fa6";
import MetricChartComponent from "../../components/metric-chart-component/metric-chart-component";
export default function LandingPage() {
	const navigate = useNavigate();

	const mockMetric = () => {
		const now = Date.now();
		const hour = 3600000;
		const day = hour * 24;

		const _ = [];
		for (let i = now - day * 6; i <= now; i += day) {
			_.push({ timestamp: i, value: Math.round(Math.random() * 100) });
		}

		return _;
	};

	return (
		<>
			<div>
				<div
					style={{
						backgroundImage: `url("${background}")`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "contain",
						maxHeight: "50svh",
						minHeight: "500px",
					}}
					className="vh-100 mb-4"
				>
					<div className="page-width position-relative h-100">
						<NavBar />

						<div className="d-flex align-items-center justify-content-center flex-column w-100 h-100 px-2" style={{ paddingTop: "77px" }}>
							<p className="d-block text-center text-primary fs-4 mb-1" style={{ maxWidth: "1000px" }}>
								My Announcements
							</p>
							<h4 className="d-block text-center fw-bold display-5" style={{ maxWidth: "1000px" }}>
								Managing secure announcements for updates and engagement.
							</h4>
							<p className="fs-6 text-muted mt-2 text-center px-md-0 px-4" style={{ maxWidth: "500px" }}>
								PCI compliant app announcements in a single solution. The right platform to help with reliable and secure
								notifications to your users.
							</p>

							<div className="mt-4 d-flex align-items-center justify-content-center gap-3">
								<Link to={"/auth/register"}>
									<button className="btn btn-lg btn-primary">Get Started</button>
								</Link>
								<Link to={"mailto:info@myannouncements.com"}>
									<button className="btn btn-lg btn-outline-primary">Request Demo</button>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div>
					{/* <strong className="m-auto text-center w-100 d-block">Join other software businesses growing with MyAnnouncements.</strong> */}

					{/* TRUSTED BY */}
					<div className="d-none align-items-center justify-content-center mt-3 mb-4">
						<div>
							<img src={ls_logo} alt="LukeStonier logo" className="d-block h-100 w-auto" style={{ maxHeight: "100px" }} />
						</div>

						<div>
							<img src={mdp_logo} alt="MyDataPro logo" className="d-block h-100 w-auto" style={{ maxHeight: "100px" }} />
						</div>
					</div>
				</div>
			</div>
			<div className="page-width">
				<div className="text-center mb-5" id="how-it-works">
					<h2 className="mb-0">How does it work?</h2>
					<p className="text-muted">Getting you familiar with the platform with these easy steps.</p>

					<div className="container-fluid m-auto row pt-5 pb-3 px-5">
						<div className="col-lg-4 col-12 mt-lg-2 mt-2">
							<div className="border px-3 pt-5 pb-3 h-100 text-start position-relative rounded">
								<div
									className="p-3 position-absolute top-0 rounded"
									style={{ transform: "translateY(-50%)", backgroundColor: "var(--bs-primary-bg-subtle)" }}
								>
									<FaCodeMerge className="h1 m-0 text-primary" />
								</div>
								<h4 className="fw-bold">Integrate</h4>
								<p className="text-muted mb-2">Quick and easy API integration makes getting started a breeze.</p>
								<p className="text-muted mb-2">No nonsence simple HTTP calls give you 100% of the functionality</p>
								<small className="w-100 text-center d-block mb-0 text-primary">
									To make integration even easier - Libraries coming soon!
								</small>
							</div>
						</div>

						<div className="col-lg-4 col-12 mt-lg-2 mt-5">
							<div className="border px-3 pt-5 pb-3 h-100 text-start position-relative rounded">
								<div
									className="p-3 position-absolute top-0 rounded"
									style={{ transform: "translateY(-50%)", backgroundColor: "var(--bs-danger-bg-subtle)" }}
								>
									<FaCloudArrowUp className="h1 m-0 text-danger" />
								</div>
								<h4 className="fw-bold">Publish</h4>
								<p className="mb-2 text-muted">
									Schedule, format and write your message and publish to your selected customers in an instant.
								</p>
								<p className="mb-0 text-muted">Smart filters allow specific targetting when producing announcements.</p>
							</div>
						</div>

						<div className="col-lg-4 col-12 mt-lg-2 mt-5">
							<div className="border px-3 pt-5 pb-3 h-100 text-start position-relative rounded">
								<div
									className="p-3 position-absolute top-0 rounded"
									style={{ transform: "translateY(-50%)", backgroundColor: "var(--bs-success-bg-subtle)" }}
								>
									<FaChartSimple className="h1 m-0 text-success" />
								</div>
								<h4 className="fw-bold">Review</h4>
								<p className="mb-2 text-muted">Track viewing insights and see who has seen your messages and when.</p>
								<p className="mb-0 text-muted">
									Ensuring that your customers have seen your announcements at a glance to maintain communication.
								</p>
							</div>
						</div>
					</div>

					<div>
						<p>
							MyAnnouncements doesnt have a complex setup or integration, get started with simple API calls from your service to ours.
						</p>
					</div>
				</div>

				<div className="text-center my-5">
					<div className="container-fluid m-auto row pt-5 pb-3 px-2 px-md-5">
						<div className="col-lg-1 col-12"></div>
						<div className="col-lg-5 col-12 mt-lg-2 mt-2">
							<div className="shadow-lg py-4 px-4 rounded mx-0 mx-md-4">
								<h4 className="text-start fs-4">Seen By</h4>
								<div className="d-flex align-items-center justify-content-between gap-3 py-2 overflow-auto">
									<img
										className="d-block"
										style={{ width: "50px", height: "50px" }}
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=test_1`}
										alt={`customer avatar`}
									/>
									<img
										className="d-block"
										style={{ width: "50px", height: "50px" }}
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=test_8`}
										alt={`customer avatar`}
									/>
									<img
										className="d-block"
										style={{ width: "50px", height: "50px" }}
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=test_3`}
										alt={`customer avatar`}
									/>
									<img
										className="d-block"
										style={{ width: "50px", height: "50px" }}
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=test_10`}
										alt={`customer avatar`}
									/>
									<img
										className="d-block"
										style={{ width: "50px", height: "50px" }}
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=test_762`}
										alt={`customer avatar`}
									/>
								</div>

								<div className="mt-3">
									<h5 className="text-start">
										<span className="number">33</span>% View rate
									</h5>
									<div className="py-3 w-100 bg-light position-relative">
										<div className="position-absolute start-0 top-0 bottom-0 bg-success h-100 w-33"></div>
									</div>

									<div className="mt-3">
										<MetricChartComponent
											metric={mockMetric()}
											title={"Views"}
											description={"Views"}
											label={"Views"}
											icon={<FaEye />}
											maxWidth={1000}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-12 mt-lg-2 mt-5 text-start d-flex flex-column align-items-start justify-content-center">
							<h6 className="mb-2 text-primary">WHY CHOOSE US</h6>
							<h1 className="b-0">In app announcements</h1>
							<h1 className="mb-3">simple and secure</h1>
							<p className="text-muted mb-0">No embedded code, know exactly what data is being sent and stored.</p>
							<div className="my-3 border-bottom w-100"></div>
							<div>
								<div className="d-flex align-items-center justify-content-start mb-1">
									<FaCircleCheck className="text-warning fs-5" />
									<span className="ms-2">Simple to use</span>
								</div>
								<div className="d-flex align-items-center justify-content-start mb-1">
									<FaCircleCheck className="text-warning fs-5" />
									<span className="ms-2">Scalable as your customer base grows</span>
								</div>
								<div className="d-flex align-items-center justify-content-start">
									<FaCircleCheck className="text-warning fs-5" />
									<span className="ms-2">Complies with PCI requirements</span>
								</div>
							</div>
							<div className="mt-3 w-100">
								<button className="btn w-50 btn-outline-primary" onClick={() => navigate("/auth/register")}>
									Get Started
								</button>
							</div>
						</div>
						<div className="col-lg-1 col-12"></div>
					</div>
				</div>
			</div>

			<div className="text-white bg-primary my-5" id="features">
				<div className="page-width">
					<div className="py-4 px-5">
						<h1 className="mb-1 text-center">Features</h1>
						<p className="text-center mb-0">Understand whats possible once you get started with MyAnnouncements</p>

						<div className="container-fluid py-3">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaRegIdBadge className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">Customer Identification</p>
									<small className="mb-0">
										Uniquely identify your customers using their user id, and more using our metadata support.
									</small>
								</div>
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaUserTag className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">User Targetting</p>
									<small className="mb-0">Target your users to see specific messages unique to their needs.</small>
								</div>
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaRegClock className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">Scheduled Posts</p>
									<small className="mb-0">
										No need to rush. Schedule announcements to go live and disappear when you need them.
									</small>
								</div>
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaChartSimple className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">Performance Metrics</p>
									<small className="mb-0">
										Track who has seen your announcements quickly and easily, along with historic view performance
									</small>
								</div>
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaUsers className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">Custom User Groups</p>
									<small className="mb-0">Group customers for easier management and targetting when sending announcements.</small>
								</div>
								<div className="col-lg-4 col-md-6 col-12 text-center p-4 px-4">
									<div className="mb-0">
										<div className="p-3 w-content m-auto rounded-circle">
											<FaRobot className="display-4" style={{ color: "var(--bs-primary-bg-subtle)" }} />
										</div>
									</div>
									<p className="fw-bold fs-6 mb-0">AI Writing Assistant</p>
									<small className="mb-0">
										Perfect your announcmenet messages with our AI Writing Assistant{" "}
										<span style={{ color: "var(--bs-primary-bg-subtle)" }}>*IN BETA*</span>
									</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="page-width">
				{/* <div className="text-center my-5">
					<h2 className="mb-0">About Us</h2>
					<p className="text-muted">Find out more about why we do what we do.</p>

					<div className="d-flex align-items-center justify-content-between"></div>
				</div> */}

				{/* PRICING */}
				<div className="text-center py-5" id="pricing">
					<h2 className="mb-0">Pricing</h2>
					<p className="text-muted mb-3">Choose the plan that works for you.</p>
					<div className="container-fluid m-auto row">
						<div className="col-xxl-3 col-xl-6 col-md-12 mt-2">
							<PricingCard tier={APP_TIERS.ESSENTIAL} callback={() => navigate("/auth/register")} />
						</div>
						<div className="col-xxl-3 col-xl-6 col-md-12 mt-2">
							<PricingCard tier={APP_TIERS.PREMIUM} callback={() => navigate("/auth/register")} />
						</div>
						<div className="col-xxl-3 col-xl-6 col-md-12 mt-2">
							<PricingCard tier={APP_TIERS.ADVANCED} callback={() => navigate("/auth/register")} />
						</div>
						<div className="col-xxl-3 col-xl-6 col-md-12 mt-2">
							<PricingCard tier={APP_TIERS.ENTERPRISE} callback={() => navigate("/auth/register")} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
