import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { SubscriptionTier } from "../../models/data/subscription-tier";
import "./pricing-card.scss";

export class TierFeature {
	constructor(public text: string, public active: boolean = true, public tooltip: string = "") {}
}

export class Tier {
	constructor(
		public tierLevel: SubscriptionTier,
		public iconColour: string,
		public backgroundColours: string[],
		public title: string,
		public sub: string,
		public pricePrefix: string,
		public price: number | string,
		public priceSuffix: string,
		public features: TierFeature[],
		public accent: boolean,
		public buttonClass: string,
		public icon: JSX.Element
	) {}
}

export default function PricingCard({
	tier,
	interactable = true,
	showButtons = true,
	showPrice = true,
	callback = (subscriptionTier: SubscriptionTier) => {},
}: {
	tier: Tier;
	interactable?: boolean;
	showButtons?: boolean;
	showPrice?: boolean;
	callback?: (subscriptionTier: SubscriptionTier) => void;
}) {
	const renderTierFeature = (feature: TierFeature) => {
		return (
			<>
				{feature.active ? <FaCheckCircle className="text-success me-2" /> : <FaTimesCircle className="text-secondary me-2" />}
				{feature.active ? (
					<span>{feature.text}</span>
				) : (
					<span className="text-muted">
						<s>{feature.text}</s>
					</span>
				)}
			</>
		);
	};

	const renderTier = (tier: Tier) => {
		return (
			<>
				<div
					style={{ minWidth: 300, maxWidth: 500 }}
					className={`m-auto w-100 card h-100 py-3 ${interactable ? "hover-shadow" : null} text-dark`}
				>
					<div className="card-body pt-0">
						<div className="">
							<div className={`display-3 text-center m-0 ${tier.iconColour}`}>{tier.icon}</div>
						</div>
						<div className="text-center">
							<div className="card-title h4 my-0">{tier.title}</div>
							<h6 className={`card-subtitle my-0`}>
								<small>{tier.sub}</small>
							</h6>
						</div>
					</div>
					<div className="mx-3 border-top"></div>
					<div className="card-body">
						<div>
							{tier.features.map((f, i) => {
								return (
									<li key={`${tier.title}_feat_${i}`} className="d-flex py-1 align-items-center">
										{renderTierFeature(f)}
									</li>
								);
							})}
						</div>
					</div>

					<div>
						{showPrice ? (
							<div>
								<p className="text-center my-0">
									<strong className="h3">
										{tier.pricePrefix}
										<span className="number">{tier.price}</span>
									</strong>
									<small>{tier.priceSuffix}</small>
								</p>
							</div>
						) : (
							<></>
						)}
					</div>

					{showButtons ? (
						<div className="card-body pb-0">
							<button
								className={`btn btn-lg ${tier.buttonClass} container-fluid`}
								onClick={() => {
									callback(tier.tierLevel);
								}}
							>
								Choose Plan
							</button>
						</div>
					) : null}
				</div>
			</>
		);
	};

	return renderTier(tier);
}
