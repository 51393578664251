import React, { useCallback, useEffect, useState } from "react";
import SiteUser from "../../models/data/SiteUser";
import { IPaginationHeaders, IPaginationResponse, SORTBY } from "../../models/interfaces/IPaginationHeaders";
import { IErrorResp } from "../../models/responses/IErrorResp";
import PaginationDataResponse from "../../models/responses/PaginationDataResponse";
import CustomerService from "../../services/customer-service";
import EventEmitter from "../../services/event/event-emitter";
import StatefullInput from "../statefull-input/statefull-input";
import "./search-customer.scss";
export default function SearchCustomer({
	searchResults,
	paginationResponse = (pagination: IPaginationResponse) => {},
	loadingEmitter = new EventEmitter<boolean>(),
	paginateEmitter = new EventEmitter<number>(),
}: {
	searchResults: EventEmitter<SiteUser[]>;
	paginationResponse?: (pagination: IPaginationResponse) => void;
	loadingEmitter?: EventEmitter<boolean>;
	paginateEmitter?: EventEmitter<number>;
}) {
	const [searchDebounce, setSearchDebounce] = useState<number>(-1);
	const [searching, setSearching] = useState<boolean>(false);
	const [filter, setFilter] = useState<string>();
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	const Search = useCallback(
		(page: number) => {
			var localFilter = filter;
			if (localFilter === undefined || localFilter === "" || localFilter.replace(" ", "") === "") localFilter = "*";

			setSearching(true);
			loadingEmitter.emit(true);
			var _ = window.setTimeout(() => {
				var pagination: IPaginationHeaders = {
					page: page,
					pageSize: 20,
					sortBy: SORTBY.DESC,
				};
				CustomerService.GetCustomers(localFilter || "*", pagination, {
					success(_: PaginationDataResponse<SiteUser[]>) {
						searchResults.emit(_.data);
						paginationResponse(_.pagination);
					},
					error(err) {
						errorState.emit(err.error);
						searchResults.emit([]);
						paginationResponse({
							page: -1,
							pageSize: 0,
							totalCount: 0,
							hasPrev: false,
							hasNext: false,
							nextPage: -1,
							prevPage: -1,
						});
					},
					always() {
						setSearching(false);
						loadingEmitter.emit(false);
					},
				});
			}, 500);
			return _;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter, searchResults, errorState]
	);

	const SearchDebounce = useCallback(() => {
		window.clearTimeout(searchDebounce);
		var debounceId = Search(0);
		setSearchDebounce(debounceId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Search]);

	useEffect(() => {
		SearchDebounce();
	}, [filter, SearchDebounce]);

	useEffect(() => {
		const paginationListener = paginateEmitter.subscribe((page: number) => {
			setSearchDebounce(Search(page));
		});

		return () => {
			paginateEmitter.unsubscribe(paginationListener);
		};
	}, [Search, paginateEmitter]);

	return (
		<>
			<StatefullInput
				stateId={"customer_search"}
				defaultValue={""}
				placeholder={"Name / User Id / Group Id"}
				inputType={"text"}
				useValidation={false}
				showErrors={true}
				autocompleteType={"off"}
				loading={searching && filter !== ""}
				stateChangeEvent={errorState}
				onChangeCallback={(val: string) => {
					// do the debouce search
					setFilter(val);
				}}
			/>
		</>
	);
}
