import React, { useCallback, useEffect, useState } from "react";
import { FaCheck, FaChevronRight, FaEye, FaEyeSlash, FaPlus, FaRegClipboard, FaX } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import AccountInviteModal from "../../components/account-invite-modal/account-invite-modal";
import BillingSection from "../../components/billing-section/billing-section";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal";
import StatefullInput from "../../components/statefull-input/statefull-input";
import Capitalise from "../../misc/helpers/text-format";
import DomainSettings from "../../models/data/domain-settings";
import UserAccount from "../../models/data/user-account";
import { ToastConfig } from "../../models/interfaces/IToast";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import SettingService from "../../services/setting-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import "./dashboard-settings.scss";
export default function DashboardSettings() {
	const navigate = useNavigate();
	const [apiHidden, setApiHidden] = useState(true);
	const [settings, setSettings] = useState<DomainSettings>();
	const [refreshSettings] = useState<EventEmitter<void>>(new EventEmitter<void>());

	const GetSettings = () => {
		SettingService.GetSettings({
			success: (_: DomainSettings) => {
				setSettings(_);
			},
			error: (err: HttpError) => {},
		});
	};

	useEffect(() => {
		GetSettings();

		refreshSettings.subscribe(() => {
			GetSettings();
		});
	}, [refreshSettings]);

	const copyToClipboard = useCallback(() => {
		if (settings === undefined) return;
		ToastService.OpenToast("clipboard_copy", "Copied", "API Key has been copied to clipboard", FaCheck, new ToastConfig().success().hide(1500));
		navigator.clipboard.writeText(settings.apiKey);
	}, [settings]);

	const regenerateKey = useCallback(() => {
		ModalService.OpenModal(
			"refresh_token",
			<ConfirmationModal
				modalId={"refresh_token"}
				options={{
					header: "Are you sure?",
					body: "Refreshing will expire your existing token and any existing calls will stop working.",
					confirmText: "Okay",
					declineText: "Cancel",
				}}
				callback={(_: boolean) => {
					if (_) {
						SettingService.RefreshKey({
							success: (_: DomainSettings) => {
								setSettings(_);
								ToastService.OpenToast(
									"api_key",
									"Key Updated",
									"API Key has been updated",
									FaCheck,
									new ToastConfig().success().hide(1500)
								);
							},
							error: (err: HttpError) => {
								ToastService.OpenToast(
									"api_key_fail",
									"Key Not Updated",
									"API Key failed to update.",
									FaX,
									new ToastConfig().danger().hide(1500)
								);
							},
						});
					}
				}}
			/>
		);
	}, []);

	const InviteAccount = useCallback(() => {
		const inviteModal = ModalService.OpenModal("invite_account", <AccountInviteModal modalId="invite_account" />);
		if (inviteModal === undefined) return;
		inviteModal.onClose.subscribe(() => {
			GetSettings();
		});
	}, []);

	const ListUI = () => {
		if (settings === undefined) return <></>;
		if (settings.siteAccounts.length === 0) return <></>;
		return (
			<>
				<div className="d-flex align-items-center justify-content-start">
					<h4 className="mx-1 mb-0">Domain Users</h4>
					<div className="flex-fill"></div>
					<button
						onClick={() => {
							InviteAccount();
						}}
						className="btn btn-outline-primary d-flex align-items-center justify-content-center"
					>
						<span>Add User</span>
						<FaPlus className="h5 ms-2 mb-0" />
					</button>
				</div>
				<div className="card mt-2">
					{settings.siteAccounts.map((account: UserAccount) => {
						return (
							<div
								key={account.id}
								className="interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
								onClick={() => {
									navigate(`/dashboard/account-detail/${account.id}`);
								}}
							>
								<div style={{ width: "75px", height: "75px" }} className="overflow-hidden">
									<img
										className="d-block w-100 h-100"
										src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${account.id}`}
										alt={`${account.fullName} avatar`}
									/>
								</div>
								<div className="mx-4">
									<h5 className="mb-1">{account.fullName}</h5>
									<div className="rounded text-center w-content bg-success text-white py-1 px-2">
										<small>{Capitalise(account.accountRole)}</small>
									</div>
								</div>
								{/* <span className="mx-4">
								<small>Last Seen: {RelativeTime(account.lastSeen)}</small>
							</span> */}
								<div className="flex-fill"></div>
								<FaChevronRight className="h5 mb-0" />
							</div>
						);
					})}
				</div>
			</>
		);
	};

	const settingsUI = () => {
		if (settings === undefined) return <></>;
		return (
			<div>
				<div className="card overflow-hidden mt-2 p-3 mb-5">
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<p className="mb-0">
							Site Name: <strong>{settings.siteName}</strong>
						</p>
						<div className="mb-0 d-flex align-items-center justify-content-start">
							{settings.enabled ? (
								<>
									<div className="d-flex align-items-center justify-content-start">
										<div className="p-1 rounded-circle bg-success"></div>
										<span className="ms-1">Active</span>
									</div>
								</>
							) : (
								<>
									<div className="d-flex align-items-center justify-content-start">
										<div className="p-1 rounded-circle bg-danger"></div>
										<span className="ms-1">Not Active</span>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="d-flex align-items-end justify-content-start">
						<div className="flex-fill">
							<StatefullInput
								stateId={"api_key"}
								defaultValue={settings.apiKey}
								label={"API Key"}
								inputType={apiHidden ? "password" : "text"}
								autocompleteType={"off"}
								readonly={true}
							/>
						</div>

						<button
							className="btn btn-outline-secondary ms-2"
							onClick={() => {
								setApiHidden(!apiHidden);
							}}
						>
							{apiHidden ? <FaEye /> : <FaEyeSlash />}
						</button>

						<button
							className="btn btn-outline-primary ms-2"
							onClick={() => {
								copyToClipboard();
							}}
						>
							<FaRegClipboard />
						</button>

						<button
							className="btn btn-warning ms-2"
							onClick={() => {
								regenerateKey();
							}}
						>
							Regenerate
						</button>
					</div>

					<div className="border-top border-light mt-3 mb-3"></div>
					<div>
						<BillingSection settings={settings} reloadSettings={refreshSettings} />
					</div>
				</div>
				{ListUI()}
			</div>
		);
	};

	return (
		<div>
			<h4 className="mx-1 mb-2">Application Settings</h4>
			{settings === undefined ? <></> : settingsUI()}
		</div>
	);
}
