import React, { useEffect, useState } from "react";
import Domain from "../../models/data/domain";
import DomainService from "../../services/domain-service";
import SelectableDropdown from "../selectable-dropdown/selectable-dropdown";
import "./domain-selector.scss";
export default function DomainSelector() {
	const [domains, setDomains] = useState<Domain[]>(DomainService.CurrentDomains());
	const [domainNames, setDomainNames] = useState<string[]>([]);
	const [selectedDomain, setSelectedDomain] = useState<Domain | undefined>(DomainService.SelectedDomain());

	useEffect(() => {
		setDomainNames(domains.map((d) => d.name));
	}, [domains]);

	useEffect(() => {
		if (selectedDomain === undefined && domains.length > 0) {
			setSelectedDomain(domains[0]);
			DomainService.SetSelectedDomain(domains[0]);
		} else {
			if (selectedDomain === undefined) return;
			DomainService.SetSelectedDomain(selectedDomain);
		}
	}, [selectedDomain, domains]);

	useEffect(() => {
		var domainChanged = DomainService.DomainChange.subscribe((_: Domain) => {
			setSelectedDomain(_);
		});
		var domainFetchEvent = DomainService.DomainsFetched.subscribe((_domains: Domain[]) => {
			setDomains(_domains);
		});
		return () => {
			DomainService.DomainsFetched.unsubscribe(domainFetchEvent);
			DomainService.DomainChange.unsubscribe(domainChanged);
		};
	}, []);

	return (
		<>
			<SelectableDropdown
				stateId="domain_select"
				options={domainNames}
				selectedOption={selectedDomain?.name}
				onChange={(val: string) => {
					var _selectedDomain = domains.find((d) => d.name === val);
					if (_selectedDomain === undefined) return;
					setSelectedDomain(_selectedDomain);
				}}
			/>
		</>
	);
}
