import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import RelativeTime from "../../misc/helpers/relative-time";
import SiteUser from "../../models/data/SiteUser";
import "./customer-list.scss";
export default function CustomerList({ customers }: { customers: SiteUser[] }) {
	const navigate = useNavigate();

	const ListUI = () => {
		if (customers === null || customers === undefined) return <></>;
		if (customers.length === 0) return <></>;
		return (
			<div className="card mt-2">
				{customers.map((customer: SiteUser) => {
					return (
						<div
							key={customer.id}
							className="interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
							onClick={() => {
								navigate(`/dashboard/customer-detail/${customer.id}`);
							}}
						>
							<div style={{ width: "75px", height: "75px" }} className="overflow-hidden">
								<img
									className="d-block w-100 h-100"
									src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${customer.id}`}
									alt={`${customer.name} avatar`}
								/>
							</div>
							<h6 className="mb-0 mx-4 flex-fill-even">{customer.name}</h6>
							<span className="mx-4 flex-fill-even">
								<small className="number">Last Seen: {RelativeTime(customer.lastSeen)}</small>
							</span>
							<div className="flex-fill"></div>
							<FaChevronRight className="h5 mb-0" />
						</div>
					);
				})}
			</div>
		);
	};

	return <>{ListUI()}</>;
}
