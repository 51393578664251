/* eslint-disable no-unreachable */
import React from "react";
import { FaExplosion } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Domain from "../../models/data/domain";
import DomainSettings from "../../models/data/domain-settings";
import Invoice from "../../models/data/invoice";
import { SubscriptionTier, SubscriptionTierMapper } from "../../models/data/subscription-tier";
import BillingService from "../../services/billing-service";
import DomainService from "../../services/domain-service";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import SettingService from "../../services/setting-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import SubscriptionSelectModal from "../subscription-select-modal/subscription-select-modal";
import "./billing-section.scss";

export default function BillingSection({ settings, reloadSettings }: { settings: DomainSettings; reloadSettings: EventEmitter<void> }) {
	const navigate = useNavigate();

	const CancelSubscription = () => {
		ModalService.OpenConfirmation(
			"cancel_subscription",
			"Are you sure?",
			"Cancelling this subscription will stop all functionality at the end of the current billing cycle.",
			"Okay",
			"Cancel",
			(_: boolean) => {
				if (_) {
					SettingService.CancelSubscription({
						success: (_: Domain) => {
							DomainService.SetSelectedDomain(_);
							ToastService.ShowSuccess("delete_success", "Subscription cancelled");
						},
						error(err: HttpError) {
							ToastService.ShowRespError("failed_delete", err);
						},
					});
				}
			}
		);
	};

	const RemoveApplication = () => {
		ModalService.OpenConfirmation(
			"delete_application",
			"Are you sure?",
			"Deleting this application will remove ALL data, including customers, users and announcement history.",
			"Okay",
			"Cancel",
			(_: boolean) => {
				if (_) {
					SettingService.RemoveApplication({
						success: (_: any) => {
							DomainService.GetDomains({
								success(_) {},
								error(err) {},
								always() {
									navigate("/dashboard");
								},
							});
							ToastService.ShowSuccess("delete_success", "Application deleted successfully");
						},
						error(err: HttpError) {
							ToastService.ShowRespError("failed_delete", err);
						},
					});
				}
			}
		);
	};

	const GetPastInvoices = () => {
		LoadingOverlay.Show();
		BillingService.GetPastInvoices({
			success(_) {
				ModalService.OpenInvoiceList("past_inv", _);
			},
			error(err: HttpError) {
				ToastService.ShowRespError("past_inv_err", err);
			},
			always() {
				LoadingOverlay.Hide();
			},
		});
	};

	const GetUpcomingInvoice = () => {
		LoadingOverlay.Show();
		BillingService.GetUpcomingInvoice({
			success(_) {
				ModalService.OpenInvoice("upcoming_inv", _, false, () => {});
			},
			error(err: HttpError) {
				ToastService.ShowRespError("inv_err", err);
			},
			always() {
				LoadingOverlay.Hide();
			},
		});
	};

	const StartSubscription = () => {
		const SubCallback = (subTier: SubscriptionTier) => {
			BillingService.CheckoutSite(subTier, {
				success: (_: { url: string }) => {
					window.location.href = _.url;
				},
				error: (err: HttpError) => {
					ToastService.ShowRespError("sub_start_error", err);
				},
			});
		};
		ModalService.OpenModal("select_subscription", <SubscriptionSelectModal modalId={"select_subscription"} confirmCallback={SubCallback} />);
	};

	const UpgradeSubscription = (subTier: SubscriptionTier) => {
		LoadingOverlay.Show();
		BillingService.UpgradeSite(subTier, {
			success: (_: Domain) => {
				ToastService.ShowSuccess("sub_upgrade", "Your subscription has been upgraded!");
				reloadSettings.emit();
			},
			error: (err: HttpError) => {
				ToastService.ShowRespError("sub_upgrade_fail", err);
			},
			always() {
				LoadingOverlay.Hide();
			},
		});
	};

	const UpdateSubscription = () => {
		const SubCallback = (subTier: SubscriptionTier) => {
			LoadingOverlay.Show();
			BillingService.GetUpgradeInvoice(subTier, {
				success: (invoice: Invoice) => {
					ModalService.OpenInvoice("upgrade_invoice", invoice, true, (upgrade: boolean) => {
						if (upgrade) {
							UpgradeSubscription(subTier);
							ModalService.CloseModal("upgrade_invoice");
							ModalService.CloseModal("select_subscription");
						}
					});
				},
				error: (err: HttpError) => {
					ToastService.ShowRespError("upgrade_inv_fail", err);
				},
				always: () => {
					LoadingOverlay.Hide();
				},
			});
		};
		ModalService.OpenModal(
			"select_subscription",
			<SubscriptionSelectModal
				startTier={SubscriptionTierMapper.ToTier(settings.subscriptionTier)}
				modalId={"select_subscription"}
				confirmCallback={SubCallback}
			/>
		);
	};

	const UpdatePaymentDetails = () => {
		BillingService.UpdatePaymentDetails({
			success: (_: { url: string }) => {
				window.open(_.url, "_blank");
			},
			error: (err: HttpError) => {
				ToastService.ShowRespError("update_payment", err);
			},
		});
	};

	const ActiveSettings = () => {
		return (
			<div>
				<div className="d-flex align-items-stretch justify-content-start mt-2 gap-2 flex-wrap">
					<div
						style={{ minWidth: 320, maxWidth: 500 }}
						className="flex-fill-even border border p-2 rounded d-flex flex-column align-items-stretch justify-content-between"
					>
						<div>
							<p className="mb-0">Update your subscription.</p>
							<small className="mb-0 text-muted d-flex align-items-center">
								Current Subscription: <strong className="ms-1">{settings.subscriptionTier}</strong>
								{settings.pendingUpgrade ? (
									<div className="d-flex align-items-center">
										<div className="p-1 bg-warning rounded-circle ms-2"></div>
										<span className="ms-1">Pending Update</span>
									</div>
								) : (
									<></>
								)}
							</small>
						</div>

						<div className="d-flex align-items-center justify-content-start gap-2 mt-3">
							<button onClick={() => UpdateSubscription()} className="btn btn-outline-primary">
								Change Subscription
							</button>
						</div>
					</div>

					<div
						style={{ minWidth: 320, maxWidth: 500 }}
						className="flex-fill-even border border p-2 rounded d-flex flex-column align-items-stretch justify-content-between"
					>
						<div>
							<p className="mb-0">Download a PDF version of your invoices.</p>
							<small className="m-0 text-muted">Future invoices are not final and are subject to change.</small>
						</div>

						<div className="d-flex align-items-center justify-content-start gap-2 mt-3">
							<button className="btn btn-outline-primary" onClick={() => GetPastInvoices()}>
								Past Invoices
							</button>
							<button className="btn btn-primary" onClick={() => GetUpcomingInvoice()}>
								Upcoming Invoice
							</button>
						</div>
					</div>

					<div
						style={{ minWidth: 320, maxWidth: 500 }}
						className="flex-fill-even border border-danger p-2 rounded d-flex flex-column align-items-stretch justify-content-between"
					>
						<div>
							<p className="mb-0">Your subscription will not renew after the current period ends.</p>
						</div>
						<div className="d-flex align-items-center justify-content-start gap-2 mt-3">
							<button
								onClick={() => {
									CancelSubscription();
								}}
								className="btn btn-outline-danger d-flex align-items-center justify-content-center"
							>
								<span>Cancel Subscription</span>
								<FaExplosion className="h5 ms-2 mb-0" />
							</button>
						</div>
					</div>
				</div>
				<div className="mt-3 border rounded p-2">
					<h5 className="mb-1">Update Payment Details</h5>
					<div>
						<small className="mb-0">Your new payment details will be used for the next invoice.</small>
					</div>
					<button onClick={() => UpdatePaymentDetails()} className="mt-2 btn btn-outline-primary">
						Update Details
					</button>
				</div>
			</div>
		);
	};

	const NonActiveSettings = () => {
		return (
			<div className="d-flex align-items-stretch justify-content-start mt-2 gap-2 flex-wrap">
				<div
					style={{ minWidth: 320, maxWidth: 500 }}
					className="flex-fill-even border border p-2 rounded d-flex flex-column align-items-stretch justify-content-between"
				>
					<div>
						<p className="mb-0">Activate subscription</p>
						<small className="mb-0 text-muted">Start your subscription by selecting a tier and checking out.</small>
					</div>

					<div className="d-flex align-items-center justify-content-start gap-2 mt-3">
						<button className="btn btn-outline-primary" onClick={() => StartSubscription()}>
							Select Subscription
						</button>
					</div>
				</div>

				<div
					style={{ minWidth: 320, maxWidth: 500 }}
					className="flex-fill-even border border-danger p-2 rounded d-flex flex-column align-items-stretch justify-content-between"
				>
					<div>
						<p className="mb-0">Delete Application</p>
						<small className="mb-0 text-muted">This will remove all settings related to the application.</small>
					</div>
					<div className="d-flex align-items-center justify-content-start gap-2 mt-3">
						<button
							onClick={() => {
								RemoveApplication();
							}}
							className="btn btn-outline-danger d-flex align-items-center justify-content-center"
						>
							<span>Delete</span>
							<FaExplosion className="h5 ms-2 mb-0" />
						</button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<h5 className="mb-1">BILLING</h5>
			{settings.enabled ? ActiveSettings() : NonActiveSettings()}
		</div>
	);
}
