import { HTTP_CONSTANTS } from "../constants/http-constants";
import Announcement from "../models/data/announcement";
import AnnouncementDetail from "../models/data/announcement-detail";
import UserAnnouncement from "../models/data/user-announcement";
import { CreateAnnouncementRequest } from "../models/requests/CreateAnnouncementRequest";
import DomainService from "./domain-service";
import HttpService, { ResponseCallback } from "./http/http-service";

function GetAnnouncementDetail(announcementId: string, callback: ResponseCallback<AnnouncementDetail>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<AnnouncementDetail>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/announcements/${announcementId}/${currentSite.id}/detail`,
		callback.success,
		callback.error,
		callback.always
	);
}

function GetUserAnnouncements(userId: string, callback: ResponseCallback<UserAnnouncement[]>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<UserAnnouncement[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/announcements/user/${currentSite.id}/${userId}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function GetAnnouncements(callback: ResponseCallback<Announcement[]>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<Announcement[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/announcements/${currentSite.id}`,
		callback.success,
		callback.error,
		callback.always
	);
}

function CreateAnnouncement(request: CreateAnnouncementRequest, callback: ResponseCallback<Announcement>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.post<CreateAnnouncementRequest, Announcement>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/announcements/${currentSite.id}`,
		request,
		callback.success,
		callback.error,
		callback.always
	);
}

function DeleteAnnouncement(announcement: Announcement, callback: ResponseCallback<void>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.delete<void>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/announcements/${announcement.id}/${currentSite.id}/delete`,
		callback.success,
		callback.error,
		callback.always
	);
}

const AnnouncementService = {
	GetAnnouncementDetail,
	GetAnnouncements,
	GetUserAnnouncements,
	CreateAnnouncement,
	DeleteAnnouncement,
};

export default AnnouncementService;
