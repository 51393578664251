import React, { useCallback, useEffect, useState } from "react";
import { FaCheck, FaChevronLeft, FaUserMinus } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal";
import RelativeTime from "../../misc/helpers/relative-time";
import Capitalise from "../../misc/helpers/text-format";
import UserAccount from "../../models/data/user-account";
import UserSession from "../../models/data/user-session";
import { ToastConfig } from "../../models/interfaces/IToast";
import { IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import { HttpError } from "../../services/http/http-service";
import UserService from "../../services/session/user-service";
import SettingService from "../../services/setting-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import "./account-detail-page.scss";
export default function AccountDetailPage() {
	const navigate = useNavigate();
	const { accountId } = useParams();
	const [activeSession] = useState<UserSession | undefined>(UserService.getCurrentSession());
	const [isActiveUser, setIsActiveUser] = useState<boolean>(true);
	const [account, setAccount] = useState<UserAccount>();
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());

	useEffect(() => {
		setIsActiveUser(activeSession !== undefined && account !== undefined && activeSession.email === account.email);
	}, [account, activeSession]);

	useEffect(() => {
		if (accountId === undefined) return;
		SettingService.GetDomainUser(accountId, {
			success: (_: UserAccount) => {
				setAccount(_);
			},
			error: (_: HttpError) => {},
		});
	}, [accountId]);

	const RemoveAccount = useCallback(() => {
		if (accountId === undefined) return;
		ModalService.OpenModal(
			"confirm_removal",
			<ConfirmationModal
				modalId={"confirm_removal"}
				options={{
					header: "Are you sure?",
					body: "Removing this account will remove access for this user.",
					confirmText: "Okay",
					declineText: "Cancel",
				}}
				callback={(_: boolean) => {
					if (_) {
						SettingService.RemoveUser(accountId, {
							success: (_: any) => {
								ToastService.OpenToast(
									"removal_complete",
									"Removal Success",
									"Account has been removed from the domain",
									FaCheck,
									new ToastConfig().success().hide(3000)
								);
								navigate("/dashboard/settings");
							},
							error: (err: HttpError) => {
								errorState.emit(err.error);
								ToastService.ShowRespError("fail_delete", err);
							},
						});
					}
				}}
			/>
		);
	}, [accountId, errorState, navigate]);

	const UserUI = () => {
		if (account === undefined)
			return (
				<>
					<p className="text-center w-100 mt-3">No user found.</p>
				</>
			);
		return (
			<div className="card overflow-hidden mt-2 p-3">
				<div>
					<div className="d-flex align-items-start justify-content-between mb-4">
						<div className="d-flex align-items-start justify-content-between">
							<div style={{ width: "75px", height: "75px" }} className="overflow-hidden">
								<img
									className="d-block w-100 h-100"
									src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${account.id}`}
									alt={`${account.fullName} avatar`}
								/>
							</div>
							<div className="ms-3">
								<p className="mb-0">
									Id: <strong>{account.id}</strong>
								</p>
								<p className="mb-0">
									Name: <strong>{account.fullName}</strong>
								</p>
								<p className="mb-0">
									Email: <strong>{account.email || "N/A"}</strong>
								</p>
							</div>
						</div>
						<div>
							<p className="mb-0 text-end number">
								Invited: <strong>{RelativeTime(account.added)}</strong>
							</p>
							<p className="mb-0 text-end number">
								Registered Since: <strong>{RelativeTime(account.registerDate)}</strong>
							</p>
						</div>
					</div>
					<div>
						<p className="mb-0">
							User Type: <strong>{Capitalise(account.accountRole)}</strong>
						</p>
						<small className=" mb-0 text-danger">
							{account.accountRole === "admin"
								? "This user has all permissions including changing site settings, billing access & user management."
								: "This user has permission to view and create announcements as well as customer profiles."}
						</small>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<div className="d-flex align-items-center justify-content-start mb-2">
				<div
					onClick={() => navigate(-1)}
					className="interactable rounded-circle d-flex align-items-center justify-content-center"
					style={{ width: "30px", height: "30px" }}
				>
					<FaChevronLeft className="h5 mb-0" />
				</div>

				<h4 className="mx-1 mb-0">Account Details</h4>
				<div className="flex-fill"></div>
				{account === undefined ? (
					<></>
				) : (
					<button
						disabled={isActiveUser}
						onClick={() => {
							RemoveAccount();
						}}
						className={`btn ${
							isActiveUser ? "btn-outline-secondary" : "btn-outline-danger"
						} d-flex align-items-center justify-content-center`}
					>
						<span>Remove</span>
						<FaUserMinus className="h5 ms-2 mb-0" />
					</button>
				)}
			</div>

			{UserUI()}
		</div>
	);
}
