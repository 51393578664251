import { HTTP_CONSTANTS } from "../constants/http-constants";
import { environment } from "../environment/environment";
import Domain from "../models/data/domain";
import Invoice from "../models/data/invoice";
import { SubscriptionTier } from "../models/data/subscription-tier";
import DomainService from "./domain-service";
import HttpService, { ResponseCallback } from "./http/http-service";

function CheckoutSite(subscriptionTier: SubscriptionTier, callback: ResponseCallback<{ url: string }>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.post<{ tier: SubscriptionTier }, { url: string }>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/checkout`,
		{ tier: subscriptionTier },
		callback.success,
		callback.error,
		callback.always,
		{ domain: environment.production ? "https://www.myannouncements.com" : "http://localhost:3000" }
	);
}

function GetUpgradeInvoice(subscriptionTier: SubscriptionTier, callback: ResponseCallback<Invoice>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.post<{ tier: SubscriptionTier }, Invoice>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/upgrade-invoice`,
		{ tier: subscriptionTier },
		callback.success,
		callback.error,
		callback.always,
		{ domain: environment.production ? "https://www.myannouncements.com" : "http://localhost:3000" }
	);
}

function GetOutstandingInvoice(callback: ResponseCallback<Invoice>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<Invoice>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/outstanding-invoice`,
		callback.success,
		callback.error,
		callback.always
	);
}

function UpgradeSite(subscriptionTier: SubscriptionTier, callback: ResponseCallback<Domain>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.post<{ tier: SubscriptionTier }, Domain>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/upgrade`,
		{ tier: subscriptionTier },
		callback.success,
		callback.error,
		callback.always,
		{ domain: environment.production ? "https://www.myannouncements.com" : "http://localhost:3000" }
	);
}

function GetPastInvoices(callback: ResponseCallback<Invoice[]>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<Invoice[]>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/past-invoices`,
		callback.success,
		callback.error,
		callback.always
	);
}

function GetUpcomingInvoice(callback: ResponseCallback<Invoice>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<Invoice>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/upcoming-invoice`,
		callback.success,
		callback.error,
		callback.always
	);
}

function UpdatePaymentDetails(callback: ResponseCallback<{ url: string }>): void {
	const currentSite = DomainService.SelectedDomain();
	if (currentSite === undefined) {
		if (callback.always) callback.always();
		return;
	}
	HttpService.get<{ url: string }>(
		`${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/payment/${currentSite.id}/update-payment`,
		callback.success,
		callback.error,
		callback.always
	);
}

function RenderPrice(minorPrice: number): string {
	const negative = minorPrice < 0;
	minorPrice = Math.abs(minorPrice);
	const majorPrice = minorPrice / 100;
	return `${negative ? "-" : ""} £${majorPrice.toFixed(2)}`;
}

const BillingService = {
	CheckoutSite,
	GetPastInvoices,
	GetUpcomingInvoice,
	GetUpgradeInvoice,
	UpgradeSite,
	RenderPrice,
	UpdatePaymentDetails,
	GetOutstandingInvoice,
};

export default BillingService;
