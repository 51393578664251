import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "./statefull-date-picker.scss";

export default function StatefullDatePicker({
	stateId,
	label,
	placeholder,
	defaultValue,
	onChangeCallback,
}: {
	stateId: string;
	label?: string;
	placeholder?: string;
	defaultValue?: Date;
	onChangeCallback?: (_: Date | undefined) => void;
}) {
	const [value, setValue] = useState<Date | undefined>(defaultValue);

	useEffect(() => {
		if (onChangeCallback === undefined) return;
		onChangeCallback(value);
	}, [onChangeCallback, value]);

	return (
		<div className="">
			<label htmlFor={`${stateId}Input`} className="form-label mb-0">
				{label}
			</label>
			<DateTimePicker
				className="w-100 bg-light text-black"
				format="DD/MM/YYYY HH:mm"
				label={placeholder}
				onChange={(_: Date | null) => {
					setValue(_ === null ? undefined : _);
				}}
			/>
		</div>
	);
}
