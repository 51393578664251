export enum SubscriptionTier {
	ESSENTIAL = 0,
	PREMIUM = 1,
	ADVANCED = 2,
	ENTERPRISE = 3,
}

export class SubscriptionTierMapper {
	public static ToTier(val: string): SubscriptionTier {
		switch (val.toUpperCase()) {
			case "ESSENTIAL":
				return SubscriptionTier.ESSENTIAL;
			case "PREMIUM":
				return SubscriptionTier.PREMIUM;
			case "ADVANCED":
				return SubscriptionTier.ADVANCED;
			case "ENTERPRISE":
				return SubscriptionTier.ENTERPRISE;
			default:
				return SubscriptionTier.ESSENTIAL;
		}
	}

	public static FromTier(tier: SubscriptionTier): "ESSENTIAL" | "PREMIUM" | "ADVANCED" | "ENTERPRISE" {
		switch (tier) {
			case SubscriptionTier.ESSENTIAL:
				return "ESSENTIAL";
			case SubscriptionTier.PREMIUM:
				return "PREMIUM";
			case SubscriptionTier.ADVANCED:
				return "ADVANCED";
			case SubscriptionTier.ENTERPRISE:
				return "ENTERPRISE";
			default:
				return "ESSENTIAL";
		}
	}
}
