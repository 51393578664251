import SiteUser from "../data/SiteUser";

export class CreateAnnouncementRequest {
	customers: SiteUser[] = [];
	constructor(
		public message: string,
		public status: "INFO" | "WARNING" | "ALERT",
		public target: "All Users" | "Selected Users Only",
		public availableFrom?: Date,
		public availableTo?: Date
	) {}
}
