import React, { useCallback, useEffect, useState } from "react";
import { FaCheck, FaChevronLeft, FaChevronRight, FaEye, FaTrash, FaX } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal";
import CustomerList from "../../components/customer-list/customer-list";
import MetricChartComponent from "../../components/metric-chart-component/metric-chart-component";
import StatefullInput from "../../components/statefull-input/statefull-input";
import CustomFormat from "../../misc/helpers/date-format";
import RelativeTime from "../../misc/helpers/relative-time";
import AnnouncementDetail, { UserSeenDetail } from "../../models/data/announcement-detail";
import { AnnouncementMetrics } from "../../models/data/announcement-metrics";
import { ToastConfig } from "../../models/interfaces/IToast";
import AnnouncementService from "../../services/announcement-service";
import DomainService from "../../services/domain-service";
import { HttpError } from "../../services/http/http-service";
import MetricService from "../../services/metric-service";
import ModalService from "../../services/ui/modal-service";
import ToastService from "../../services/ui/toast-service";
import "./announcement-detail-page.scss";
export default function AnnouncementDetailPage() {
	const navigate = useNavigate();
	const [announcement, setAnnouncement] = useState<AnnouncementDetail>();
	const [announcementMetrics, setAnnouncementMetrics] = useState<AnnouncementMetrics>();
	let { announcementId } = useParams();

	useEffect(() => {
		if (announcementId === undefined) {
			navigate("/dashboard");
			return;
		}
		AnnouncementService.GetAnnouncementDetail(announcementId, {
			success(_) {
				setAnnouncement(_);
				MetricService.GetAnnouncementMetrics(_.announcement.id, {
					success(metrics) {
						setAnnouncementMetrics(metrics);
					},
					error(err) {},
				});
			},
			error(err) {},
		});
	}, [announcementId, navigate]);

	const DeleteAnnouncement = useCallback(() => {
		if (announcement === undefined || announcement.announcement === undefined) return;
		ModalService.OpenModal(
			"confirm_removal",
			<ConfirmationModal
				modalId={"confirm_removal"}
				options={{
					header: "Are you sure?",
					body: "Removing this announcement can not be undone.",
					confirmText: "Okay",
					declineText: "Cancel",
				}}
				callback={(_: boolean) => {
					if (_) {
						AnnouncementService.DeleteAnnouncement(announcement.announcement, {
							success: function (_: void): void {
								ToastService.OpenToast(
									"success_delete",
									"Announcement Deleted",
									"Announcement has been removed",
									FaCheck,
									new ToastConfig().success()
								);
								navigate("/dashboard");
							},
							error: function (err: HttpError): void {
								ToastService.OpenToast(
									"failed_delete",
									"Failed To Delete",
									"Announcement was not deleted",
									FaX,
									new ToastConfig().danger()
								);
							},
						});
					}
				}}
			/>
		);
	}, [announcement, navigate]);

	const SeenByListUI = (reads: UserSeenDetail[], access: boolean) => {
		if (!access) return <>Subscription level doesnt allow announcement metrics.</>;
		if (reads.length === 0) return <></>;
		return (
			<div className="card mt-2">
				{reads.map((customer: UserSeenDetail) => {
					return (
						<div
							key={customer.id}
							className="interactable px-3 py-2 d-flex justify-content-between align-items-center list-group-item"
							onClick={() => {
								navigate(`/dashboard/customer-detail/${customer.id}`);
							}}
						>
							<div style={{ width: "75px", height: "75px" }} className="overflow-hidden">
								<img
									className="d-block w-100 h-100"
									src={`https://api.dicebear.com/7.x/thumbs/svg?radius=50&backgroundColor=f8f9fa&scale=90&eyes=variant5W10,variant5W12,variant5W14,variant5W16,variant6W10,variant6W12,variant6W14,variant6W16,variant8W12,variant8W14,variant8W16&seed=${customer.id}`}
									alt={`${customer.name} avatar`}
								/>
							</div>
							<h6 className="mb-0 mx-4">{customer.name}</h6>
							<span className="mx-4 number">
								<small>Viewed: {RelativeTime(customer.viewed)}</small>
							</span>
							<div className="flex-fill"></div>
							<FaChevronRight className="h5 mb-0" />
						</div>
					);
				})}
			</div>
		);
	};

	const announcementContent = () => {
		if (announcement === undefined)
			return (
				<>
					<p className="text-center w-100 mt-3">No announcement found.</p>
				</>
			);
		return (
			<>
				<div className="card overflow-hidden p-3 mt-2">
					<div>
						<div className="d-flex align-items-start justify-content-between mb-2">
							<p className="mb-0">
								Type: <strong>{announcement.announcement.type}</strong>
							</p>
							<div>
								<p className="mb-0 number">
									Created: <strong>{RelativeTime(announcement.announcement.date)}</strong>
								</p>
								{announcement.createdBy !== undefined ? (
									<p className="mb-0 d-block text-truncate" style={{ maxWidth: 250 }}>
										Author:{" "}
										{DomainService.DomainUser !== undefined && DomainService.DomainUser()?.accountRole === "admin" ? (
											<Link to={`/dashboard/account-detail/${announcement.createdBy.id}`}>
												<strong className="text-primary text-decoration-underline">{announcement.createdBy.name}</strong>
											</Link>
										) : (
											<strong>{announcement.createdBy.name}</strong>
										)}
									</p>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className="d-flex align-items-start justify-content-between mb-2">
							<div>
								<p className="mb-2">
									Status: <strong>{announcement.announcement.status}</strong>
								</p>
							</div>

							<div>
								<p className="mb-0">
									Available From:{" "}
									<strong>
										{announcement.announcement.availableFrom !== null
											? CustomFormat(announcement.announcement.availableFrom)
											: "Not Set"}
									</strong>
								</p>
								<p className="mb-0">
									Available Until:{" "}
									<strong>
										{announcement.announcement.availableTo !== null
											? CustomFormat(announcement.announcement.availableTo)
											: "Not Set"}
									</strong>
								</p>
							</div>
						</div>

						<div className="">
							<StatefullInput
								stateId={""}
								label={"Message:"}
								readonly={true}
								defaultValue={announcement.announcement.message}
								inputType={"textarea"}
								autocompleteType={"off"}
							/>
						</div>
					</div>

					{announcementMetrics !== undefined && (
						<div className="mt-3">
							<MetricChartComponent
								metric={announcementMetrics.views}
								title={"Views"}
								description={""}
								label={"Views"}
								icon={<FaEye />}
								maxWidth={2000}
							/>
						</div>
					)}
				</div>

				<div className="d-flex align-items-top justify-content-between gap-2">
					{announcement.announcement.type === "USER" ? (
						<div className="w-50 flex-fill">
							<h4 className="mb-0 mt-4 mx-1">Available To</h4>
							<CustomerList customers={announcement.announcement.users} />
						</div>
					) : (
						<></>
					)}

					<div className="flex-fill">
						<h4 className="mb-0 mt-4 mx-1">Seen By</h4>
						{SeenByListUI(announcement.reads, announcement.hasMetricAccess)}
					</div>
				</div>
			</>
		);
	};

	return (
		<div>
			<div className="d-flex align-items-center justify-content-start">
				<div
					className="interactable rounded-circle d-flex align-items-center justify-content-center"
					style={{ width: "30px", height: "30px" }}
					onClick={() => navigate(-1)}
				>
					<FaChevronLeft className="h5 mb-0" />
				</div>

				<h4 className="mx-1 mb-0">Announcement Details</h4>
				<div className="flex-fill"></div>
				{announcement === undefined ? (
					<></>
				) : (
					<button onClick={() => DeleteAnnouncement()} className="btn btn-outline-danger d-flex align-items-center justify-content-center">
						<span>Delete</span>
						<FaTrash className="h5 ms-2 mb-0" />
					</button>
				)}
			</div>
			{announcementContent()}
		</div>
	);
}
