import React, { useCallback, useEffect, useState } from "react";
import Domain from "../../models/data/domain";
import SiteUser from "../../models/data/SiteUser";
import { CreateAnnouncementRequest } from "../../models/requests/CreateAnnouncementRequest";
import { IErrorResp } from "../../models/responses/IErrorResp";
import DomainService from "../../services/domain-service";
import EventEmitter from "../../services/event/event-emitter";
import SelectableDropdown from "../../components/selectable-dropdown/selectable-dropdown";
import StatefullInput from "../../components/statefull-input/statefull-input";
import "./create-announcement-page.scss";
import SelectCustomer from "../../components/select-customer/select-customer";
import AnnouncementService from "../../services/announcement-service";
import { Link, useNavigate } from "react-router-dom";
import ToastService from "../../services/ui/toast-service";
import { FaCheck, FaChevronLeft } from "react-icons/fa6";
import { ToastConfig } from "../../models/interfaces/IToast";
import StatefullDatePicker from "../../components/statefull-date-picker/statefull-date-picker";
import { HttpError } from "../../services/http/http-service";

export default function CreateAnnouncementPage() {
	const [createRequest, setCreateRequest] = useState<CreateAnnouncementRequest>(new CreateAnnouncementRequest("", "INFO", "All Users"));
	const [currentDomain, setCurrentDomain] = useState<Domain | undefined>(DomainService.SelectedDomain());
	const [customerEventEmitter] = useState<EventEmitter<SiteUser[]>>(new EventEmitter<SiteUser[]>());
	const [resetValidation] = useState<EventEmitter<void>>(new EventEmitter<void>());
	const [errorState] = useState<EventEmitter<IErrorResp>>(new EventEmitter<IErrorResp>());
	const navigate = useNavigate();

	useEffect(() => {
		const domainChangeEvent = DomainService.DomainChange.subscribe((d: Domain) => {
			setCreateRequest(new CreateAnnouncementRequest("", "INFO", "All Users"));
			resetValidation.emit();
			setCurrentDomain(d);
		});
		const customerFetchEvent = customerEventEmitter.subscribe((customers: any[]) => {
			resetValidation.emit();
			setCreateRequest({ ...createRequest, customers: customers });
		});
		return () => {
			DomainService.DomainChange.unsubscribe(domainChangeEvent);
			customerEventEmitter.unsubscribe(customerFetchEvent);
		};
	}, [createRequest, customerEventEmitter, resetValidation]);

	const CreateAnnouncement = useCallback(() => {
		const errors = [];
		if (createRequest.message === "" || createRequest.message === undefined)
			errors.push({ key: "announcement_message", value: "Message can not be empty." });

		if (createRequest.message !== undefined) {
			// eslint-disable-next-line no-useless-escape
			const html_regex = /(\<(\/)?(\w)*(\d)?\>)/g;
			if (html_regex.test(createRequest.message)) {
				errors.push({ key: "announcement_message", value: "Message contains invalid characters. HTML is not permitted." });
			}
		}

		if (createRequest.target === "Selected Users Only" && createRequest.customers.length < 1)
			errors.push({ key: "announcement_target", value: "No customers selected." });

		if (errors.length > 0) errorState.emit({ error: "", fixes: errors });
		else
			AnnouncementService.CreateAnnouncement(createRequest, {
				success(_) {
					navigate("/dashboard");
					ToastService.OpenToast(
						"announcement_complete",
						"Success",
						"Announcement created",
						FaCheck,
						new ToastConfig({
							headerColour: "success",
							borderColour: "success",
							iconColour: "success",
						})
					);
				},
				error(err: HttpError) {
					ToastService.ShowRespError("failed_announcement", err);
				},
			});
	}, [createRequest, errorState, navigate]);

	return (
		<>
			{currentDomain === undefined ? (
				<></>
			) : (
				<>
					<div>
						<div className="d-flex align-items-center justify-content-start mb-1">
							<Link
								to={"/dashboard"}
								className="interactable rounded-circle d-flex align-items-center justify-content-center"
								style={{ width: "30px", height: "30px" }}
							>
								<FaChevronLeft className="h5 mb-0" />
							</Link>

							<h4 className="mx-1 mb-0">
								Create new announcement for <strong>{currentDomain?.name}</strong>
							</h4>
						</div>

						<div className="card p-3 mt-2">
							<p className="mb-0 pb-3 border-bottom border-light">
								Creating an announcement will publish a message that will be available for all of the selected users. This can be
								removed at any time.
							</p>

							<div className="mt-3">
								<StatefullInput
									stateId={`announcement_message`}
									label={"Announcement Message"}
									defaultValue={""}
									inputType={"textarea"}
									autocompleteType={"off"}
									onChangeCallback={(val: string) => {
										setCreateRequest({ ...createRequest, message: val } as CreateAnnouncementRequest);
									}}
									stateChangeEvent={errorState}
									resetValidation={resetValidation}
								/>
							</div>

							<div className="mt-2">
								<p className="mb-3">
									Set the time this announcement will become available and expire. Leaving these blank will make the announcement
									permanently available.
								</p>

								<div className="d-block d-md-flex justify-contet-between align-items-start gap-2">
									<div className="w-100 md-100">
										<StatefullDatePicker
											stateId="announcement_start"
											placeholder="Visbile From (optional)"
											onChangeCallback={(_: Date | undefined) => {
												setCreateRequest({ ...createRequest, availableFrom: _ } as CreateAnnouncementRequest);
											}}
										/>
									</div>
									<div className="w-100 md-100">
										<StatefullDatePicker
											stateId="announcement_end"
											placeholder="Visbile Until (optional)"
											onChangeCallback={(_: Date | undefined) => {
												setCreateRequest({ ...createRequest, availableTo: _ } as CreateAnnouncementRequest);
											}}
										/>
									</div>
								</div>
							</div>

							<div className="mt-3 d-block d-md-flex justify-contet-between align-items-start gap-2">
								<div className="w-100 md-100">
									<SelectableDropdown
										stateId={`announcement_status`}
										label={"Announcement Status"}
										options={["INFO", "WARNING", "ALERT"]}
										selectedOption={createRequest?.status}
										onChange={function (val: "INFO" | "WARNING" | "ALERT"): void {
											setCreateRequest({ ...createRequest, status: val } as CreateAnnouncementRequest);
										}}
										stateChangeEvent={errorState}
										resetValidation={resetValidation}
									/>
								</div>
								<div className="w-100 md-100">
									<SelectableDropdown
										stateId={`announcement_target`}
										label={"Announcement Target"}
										options={["All Users", "Selected Users Only"]}
										selectedOption={createRequest?.target}
										onChange={function (val: "All Users" | "Selected Users Only"): void {
											setCreateRequest({ ...createRequest, target: val } as CreateAnnouncementRequest);
										}}
										useValidation={true}
										stateChangeEvent={errorState}
										resetValidation={resetValidation}
									/>
								</div>
							</div>

							{createRequest && createRequest.target === "Selected Users Only" ? (
								<SelectCustomer customerEventEmitter={customerEventEmitter} />
							) : (
								<></>
							)}
							<div className="mt-2 d-flex align-items-center justify-content-end">
								<button
									className="btn btn-primary"
									onClick={() => {
										CreateAnnouncement();
									}}
								>
									Complete
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
