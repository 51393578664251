import React from "react";
import { useNavigate } from "react-router-dom";
import "./site-not-active-page.scss";
export default function SiteNotActivePage() {
	const navigate = useNavigate();

	return (
		<div className="d-flex align-items-center justify-content-center">
			{/* ACTIVATE */}
			<div className="card p-3 w-100" style={{ maxWidth: 700 }}>
				<h5 className="card-title">Application Not Active</h5>
				<p>
					Your application is not using an active plan, to continue using MyAnnouncements head over to your settings page and complete your
					set-up.
				</p>

				<div className="mt-3">
					<button onClick={() => navigate("/dashboard/settings")} className="btn btn-outline-primary">
						Go To Settings
					</button>
				</div>
			</div>
		</div>
	);
}
