import React, { useCallback } from "react";
import { FaPlus } from "react-icons/fa6";
import { Link } from "react-router-dom";
import logo from "../../assets/images/MA_LOGO_TEXT.png";
import logo_icon from "../../assets/images/MA_LOGO.png";
import Domain from "../../models/data/domain";
import { NavigationRoute } from "../../pages/dashboard-page";
import DomainService from "../../services/domain-service";
import ModalService from "../../services/ui/modal-service";
import CreateApplicationModal from "../create-application-modal/create-application-modal";
import "./dashboard-navigation.scss";

export default function DashboardNavigation({
	navigation = [],
	currentRoute,
	currentDomain,
}: {
	navigation: NavigationRoute[];
	currentRoute: NavigationRoute | undefined;
	currentDomain: Domain | undefined;
}) {
	const StartCreateHandler = useCallback(() => {
		var m = ModalService.OpenModal("create_application_modal", <CreateApplicationModal modalId="create_application_modal" />);
		if (m === undefined) return; //
		m.onClose.subscribe(() => {
			DomainService.GetDomains({
				success(_) {},
				error(err) {},
			});
		});
	}, []);

	return (
		<div className="px-2 py-3 border-end">
			{/* logo */}
			<div className="d-flex m-auto align-items-center justify-content-center">
				<img src={logo} height="45" className="d-none d-md-block" alt="logo" />
				<img src={logo_icon} height="30" className="my-2 d-block d-md-none" alt="logo" />
			</div>

			{/* routes */}
			<div className="mt-3 mt-md-5">
				{navigation.map((route: NavigationRoute, i: number) => {
					return (
						<div className="mt-2" key={i}>
							<Link to={route.route} className="w-100">
								<button
									className={`${
										currentRoute !== undefined && currentRoute.route === route.route
											? "btn-primary"
											: "btn-outline-primary btn-no-border"
									} btn px-3 py-2 d-flex align-items-center justify-content-start w-100`}
								>
									<div className="h4 m-0 m-0 d-flex justify-content-center align-items-center">{route.icon}</div>
									<p className="d-none d-md-block h6 m-0 ms-3">{route.routeName}</p>
								</button>
							</Link>
						</div>
					);
				})}

				{currentDomain === undefined ? (
					<></>
				) : (
					<>
						<div className="border-bottom border-light my-2"></div>
						<div className="mt-2">
							<button
								onClick={() => StartCreateHandler()}
								className="btn btn-primary px-3 py-2 d-flex align-items-center justify-content-start w-100 text-center"
							>
								<div className="h4 m-0 m-0 d-flex justify-content-center align-items-center">
									<FaPlus />
								</div>
								<p className="d-none d-md-block h6 m-0 ms-3">New Application</p>
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
