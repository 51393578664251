import React, { useCallback, useEffect, useState } from "react";
import { IErrorFix, IErrorResp } from "../../models/responses/IErrorResp";
import EventEmitter from "../../services/event/event-emitter";
import "./selectable-dropdown.scss";
export default function SelectableDropdown({
	stateId,
	label = undefined,
	options,
	selectedOption,
	onChange,
	stateChangeEvent,
	useValidation = false,
	resetValidation = new EventEmitter<void>(),
}: {
	stateId: string;
	label?: string;
	options: any[];
	selectedOption: any;
	onChange: (val: any) => void;
	stateChangeEvent?: EventEmitter<IErrorResp>;
	useValidation?: boolean;
	resetValidation?: EventEmitter<void>;
}) {
	const [_options, setOptions] = useState(options || []);
	const [_selected, setSelected] = useState(selectedOption);

	const [forceError, setForceError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isDirty, setIsDirty] = useState(false);

	const validate = useCallback(
		(errorResp: IErrorResp) => {
			setForceError(false);
			setIsDirty(true);
			setErrorMessage("");
			if (errorResp !== null && errorResp.fixes != null) {
				errorResp.fixes.forEach((fix: IErrorFix) => {
					if (fix.key === stateId) {
						setErrorMessage(fix.value);
					}

					if (fix.key === "global") setForceError(true);
				});
			}
		},
		[stateId]
	);

	const clearValidation = useCallback(() => {
		setForceError(false);
		setIsDirty(false);
		setErrorMessage("");
	}, []);

	useEffect(() => {
		const resetSub = resetValidation.subscribe(() => {
			clearValidation();
		});
		if (stateChangeEvent !== undefined) {
			const stateSubscription = stateChangeEvent.subscribe((_: IErrorResp) => {
				validate(_);
			});

			return () => {
				stateChangeEvent.unsubscribe(stateSubscription);
				resetValidation.unsubscribe(resetSub);
			};
		}
	}, [clearValidation, resetValidation, stateChangeEvent, validate]);

	const changeHandler = (val: any) => {
		onChange(val);
		setIsDirty(true);
		setSelected(val);
		clearValidation();
	};

	useEffect(() => {
		setOptions(options);
		setSelected(_selected);
	}, [_selected, options]);

	useEffect(() => {
		setOptions(options);
		setSelected(selectedOption);
	}, [stateId, options, selectedOption]);

	const validStateClass = useCallback(() => {
		if (!useValidation) return "";
		if (forceError) return "is-invalid";
		if (!isDirty) return "";
		if (isDirty && errorMessage === "") return "is-valid";
		return "is-invalid";
	}, [errorMessage, forceError, isDirty, useValidation]);

	const errorElement = useCallback(() => {
		return errorMessage ? <label className="mt-1 d-block form-label text-danger small">{errorMessage}</label> : null;
	}, [errorMessage]);

	return (
		<div>
			{label == null ? null : (
				<label htmlFor={`${stateId}Input`} className="form-label mb-0">
					{label}
				</label>
			)}
			<div className={`dropdown ${validStateClass()}`} key={stateId}>
				<button
					className={`${
						_options.length === 0 ? "disabled" : null
					} btn bg-light text-dark dropdown-toggle w-100 dropdown-selector d-flex align-items-center justify-content-between`}
					type={"button"}
					id={`DropdownButton`}
					data-bs-toggle={"dropdown"}
					aria-expanded={"false"}
				>
					<span className="me-2 ps-0 pe-4">{_selected === null ? "LOADING..." : _options.length === 0 ? "Please Select" : _selected}</span>
				</button>

				<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="DropdownButton">
					{_options.map((option: string, i: number) => {
						return (
							<li key={i}>
								<button className="dropdown-item" onClick={() => changeHandler(option)}>
									{option}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
			{useValidation && errorElement()}
		</div>
	);
}
